import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import CryptoJS from 'crypto-js';
import baseUrl from "../context/baseUrl";

const AdminDashboard = ({userInfo}) => {
  const navigation = useNavigate();
  const [orders, setOrders] = useState([]);
  const [MintValue, setMintValue] = useState();
  const [VeryGood, setVeryGoodValue] = useState();
  const [TotalOffer, setTotalOffer] = useState();
  const [error, setError] = useState(null);
  const [MintMessage, setMintMessage] = useState();
  const [CustomerOrder, setCustomerOrder] = useState();
  // console.log(MintMessage)
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `${baseUrl}/DiscountValue`,
        {
          MintValue,
          VeryGood,
        }, {
        headers: {
          "Content-Type": "application/json",
          "user-id": userInfo?.userId,
          "Authorization": `Bearer ${userInfo?.token}`,

        },
      }
      );
      setMintMessage(response.data.message);
      console.log("Data saved successfully:", response.data.message);
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  useEffect(() => {
    async function fetchOrders() {

      // Data to encrypt
      const sensitiveData = 'frontend';
      // Encryption key (must be a secret)
      const encryptionKey = 'legotwosell';
      // Encrypt the data
      const encryptedData = CryptoJS.AES.encrypt(sensitiveData, encryptionKey).toString();

      try {
        // Replace with the actual user ID
        const response = await axios.get(`${baseUrl}/data`, {
          headers: {
            "Content-Type": "application/json",
            "source": encryptedData,
          },
        });
        setOrders(response.data);
      } catch (error) {
        setError("Error fetching orders");
      }
    }

    fetchOrders();
  }, []);
  

  const fetchInfo = () => {

    // Data to encrypt
    const sensitiveData = 'frontend';
    // Encryption key (must be a secret)
    const encryptionKey = 'legotwosell';
    // Encrypt the data
    const encryptedData = CryptoJS.AES.encrypt(sensitiveData, encryptionKey).toString();

    return fetch(`${baseUrl}/data`, {
      headers: {
        "Content-Type": "application/json",
        "source": encryptedData,
      },
    })
      .then((res) => res.json())
      .then((d) => setCustomerOrder(d));
  };
  useEffect(() => {
    fetchInfo();
  }, []);
  // console.log("log", CustomerOrder.length)
  const countUnpaidOrders = (orders) => {
    return orders?.reduce((count, order) => {
      if (order.Status !== "Paid") {
        return count + 1;
      }
      return count;
    }, 0);
  };
  let NumberTotal = 0;
  const calculateTotalPrice = () => {
    let total = 0;
    
    
    orders.forEach((user) => {
      NumberTotal = countUnpaidOrders(user.Order);
      console.log("user", user);
      user.Order.forEach((order) => {
        order.orderItems.forEach((item) => {
          if(item.Status === "Paid"){
            if(order.discount>0){
              total += parseFloat(item.subTotal)*(1+order.discount/100);
            }else{
              total += parseFloat(item.subTotal);
            }
          }
        });
      });
    });
    return total.toFixed(2);
  };
  // console.log("fix")
  let ordersWithoutPaidOrRejected = 0;

  orders.forEach((user) => {
    user.Order.forEach((order) => {
      if (order.Status !== "Paid" && order.Status !== "Rejected") {
        ordersWithoutPaidOrRejected++;
      }
    });
  });

  // console.log(
  //   "Number of orders without Paid or Rejected status:",
  //   ordersWithoutPaidOrRejected
  // )
  return (
    <div>
      <main className="p-4 md:p-8 md:w-10/12 justify-center m-auto">
        <h2 className="text-2xl md:text-3xl font-bold text-center mb-8">Welcome To the Admin Dashboard</h2>
        <div className="flex flex-col md:flex-row justify-center space-y-2 md:space-y-0 md:space-x-1 ">
          {/* Left Section */}
          <div className="border-2 border-blue-500 rounded-lg  p-3 md:w-1/3 mx-2 w-full justify-center">
          <Link
                to={`${baseUrl}/export/csv/alldata6`}
                className="flex items-center"
              >
                <img
                  className="w-[10%]"
                  src="/Images/LegoCSV icon.png"
                  alt=""
                />
                <p className=" text-[black] text-[20px] text-left  font-semibold w-full">
                  Download Sets Database
                </p>
              </Link>
              <Link
                to={`${baseUrl}/export/csv`}
                className="flex items-center"
              >
                <img
                  className="w-[8%]"
                  src="/Images/emaillist3dicon.png"
                  alt=""
                />
                <p className="w-full font-semibold text-[black] text-[20px] text-left">
                  Download Email Database
                </p>
              </Link>
            
            <label className="block mb-2">Mint Discount</label>
            
            <div className="flex justify-center">
              <input 
                placeholder="Change Mint Discount %" 
                onChange={(e) => setMintValue(e.target.value)}  
                type="text"
                className="p-2 w-full  rounded-md border-2 border-blue-500 mx-auto"
              />
            </div>
            <label className="block mb-2">Very Good Discount</label>
            <div className="flex justify-center">
              <input 
                placeholder="Change Very Good Discount %" 
                onChange={(e) => setVeryGoodValue(e.target.value)}  
                type="text"
                className="p-2 w-full  rounded-md border-2 border-blue-500 mb-4 mx-auto"
              />
            </div>
            
            <div className="flex justify-center">
              <button className="bg-blue-500 text-white py-2 px-4 rounded w-auto min-w-32" onClick={handleSubmit}>Change %</button>
            </div>
            {MintValue !== "" && (
                <h4 className="text-lg pl-4 mt-5 text-green-500 font-medium">
                  {MintMessage}
                </h4>
              )}
          </div>

          {/* Right Section */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-2 w-full md:w-2/3">
            <div className="bg-white border-2 border-blue-500 rounded-lg  p-2 text-center shadow-md">
            <Link to={"/admin"} className="flex items-center flex-col">
                    <img
                      className="lg:w-[30%] w-[30%]"
                      src="/Images/totalcustomers.png"
                      alt=""
                    />
                 
                  </Link>
              <h4 className="text-xl font-bold mb-2">Customer Accounts</h4>
              <p className="text-2xl">{CustomerOrder?.length}</p>
            </div>
            <div className="bg-white border-2 border-blue-500 rounded-lg  p-2 text-center shadow-md">
            <Link to={"/add-discount-details"} className="flex items-center flex-col">
                    <img
                      className="lg:w-[30%] w-[30%]"
                      src="/Images/totalcustomers.png"
                      alt=""
                    />
                 
                  </Link>
               <h4 className="text-xl font-bold mb-2">Customer Discounts</h4>
             {/* <p className="text-2xl">{CustomerOrder?.length}</p> */}
            </div>
            <div className="bg-white border-2 border-green-500 rounded-lg  p-2 text-center shadow-md">
            <Link
                    to={"/customeroffers"}
                    className="flex items-center flex-col"
                  >
                    <img
                      className="lg:w-[30%] w-[30%]"
                      src="/Images/totalnumbericon.png"
                      alt=""
                    />
                  
                  </Link>
              <h4 className="text-xl font-bold mb-2">Total Offers</h4>
              <p className="text-2xl">{ordersWithoutPaidOrRejected}</p>
            </div>
            <div className="bg-white border-2 border-yellow-500 rounded-lg  p-2 text-center shadow-md">
            <div className="flex items-center flex-col">
                  <img
                    className="lg:w-[30%] w-[30%]"
                    src="/Images/Pound_lego_icon.png"
                    alt=""
                  />
                  </div>
              <h4 className="text-xl font-bold mb-2">Total Paid Out</h4>
              <p className="text-2xl">£{calculateTotalPrice()}</p>
            </div>
            <div className="bg-white border-2 border-blue-500 rounded-lg  p-2 text-center shadow-md">
            <div
                      onClick={() =>
                      window.open(
                        "https://lego2sell-das.vercel.app/#/admin/default"
                      )
                    }
                    className="flex items-center flex-col"
                    >
                    <img
                      className="lg:w-[30%] w-[30%]"
                      src="/Images/reportsicon.png"
                      alt=""
                    />

                      <h4 className="text-xl font-bold mb-2">Management Reports</h4>
                  </div>
              
            </div>
            <div className="bg-white border-2 border-green-500 rounded-lg  p-2 text-center shadow-md">
            <Link
                  to={"/userblogs"}
                  className="flex items-center flex-col"
                >
                  <img
                    className="lg:w-[30%] w-[30%]"
                    src="/Images/blogs.png"
                    alt=""
                  />
                  {/* <h4 className="text-base lg:text-xl text-center font-bold">
                    Blogs
                  </h4> */}
                </Link>
              <h4 className="text-xl font-bold mb-2">Blogs</h4>
            </div>
          </div>
        </div>
      </main>

      
    
            
    </div>
  );
};

export default AdminDashboard;
