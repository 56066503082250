import axios from "axios"
import React from "react"
import { useDisclosure } from "@mantine/hooks"
import CryptoJS from 'crypto-js';
import baseUrl from "../context/baseUrl"
import OfferDetails from "./OfferDetails"

const AdminOffer = ({orders, items, index, userInfo, fetchInfo }) => {
  // const [OrderOpen, setOrderOpen] = useState()
  // const [orderId, setOrderId] = useState()
  const validStatuses = ["Pending", "Received", "Checking", "Accepted"]
  // useEffect(() => {
  //   handleUpdate()
  // }, [props.userInfo?.userId, Status, Status])
  
  // const getStatus = (a) => {
  //   switch (a) {
  //     case 'Pending':
  //       return 'bg-yellow-100 text-yellow-800';
  //     case 'Received':
  //       return 'bg-blue-100 text-blue-800';
  //     case 'Checking':
  //       return 'bg-purple-100 text-purple-800';
  //     case 'Accepted':
  //       return 'bg-green-100 text-green-800';
  //     case 'Rejected':
  //       return 'bg-red-100 text-red-800';
  //     case 'Paid':
  //       return 'bg-green-100 text-green-800';
  //     default:
  //       return 'bg-gray-100 text-gray-800';
  //   }
  // };
  // const getStatusColor = () => {
  //   switch (Status) {
  //     case 'Pending':
  //       return 'bg-yellow-100 text-yellow-800';
  //     case 'Received':
  //       return 'bg-blue-100 text-blue-800';
  //     case 'Checking':
  //       return 'bg-purple-100 text-purple-800';
  //     case 'Accepted':
  //       return 'bg-green-100 text-green-800';
  //     case 'Rejected':
  //       return 'bg-red-100 text-red-800';
  //     case 'Paid':
  //       return 'bg-green-100 text-green-800';
  //     default:
  //       return 'bg-gray-100 text-gray-800';
  //   }
  // };

 
  

  const handleUpdate = (newStatus, orderId, id, userId) => {
    
    axios
      .put(`${baseUrl}/Getorder/status/${userId}`, {
        newStatus,
        orderId,
        id
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${userInfo.token}`,
        },
      }
      )
      .then((response) => {
        fetchInfo();
        // console.log("Data updated:", response.data)
        // Handle successful update
      })
      .catch((error) => {
        console.error("Error updating data:", error)
        // Handle error
      })
  }
  
  // console.log(items.user, "lego")

  const [opened, { open, close }] = useDisclosure(false)

  const handleDeleteAccount = async () => {
    // console.log(email)
    const email = items?.user?.email
    // Data to encrypt
    const sensitiveData = 'frontend';

    // Encryption key (must be a secret)
    const encryptionKey = 'legotwosell';

    // Encrypt the data
    const encryptedData = CryptoJS.AES.encrypt(sensitiveData, encryptionKey).toString();

    try {
      const response = await fetch(`${baseUrl}/delete-account`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "source": encryptedData,
          "Authorization": `Bearer ${userInfo.token}`,
        },
        body: JSON.stringify({ email }),
      })
      window.location.reload()
      const data = await response.json()
      // console.log(data)
      if (response.ok) {
        // setMessage(data.message)
        // setEmail("")
      } else {
        // setMessage(data.message)
      }
    } catch (error) {
      console.error(error)
      // setMessage("Internal Server Error")
    }
    close()
  }

  
  
  return (
    <>
    {/* { totalQty> 0 ?   */}
    <div className="">
      <div onClick={() => open()} class="py-3">
        <div class="mb-4 last:mb-0 bg-white shadow-[0_4px_20px_rgba(0,0,0,0.15)] rounded-2xl p-6 px-8 cursor-pointer">
          <div className="flex flex-wrap items-center justify-between">
            <div className="mr-auto lg:py-0 py-4 font-medium">
              Offer ID: # {orders.offerId}
              <br className="md:hidden" />
            </div>
            <div className="mr-auto lg:py-0 py-4 text-xs font-medium">
              Email Id : {items?.user?.email}
              <br className="md:hidden" />
            </div>
            <div className="mr-auto lg:py-0 py-4 font-medium">
              {`${items?.user?.Mydetails[0].title}
            ${items?.user?.Mydetails[0].firstName}
            ${items?.user?.Mydetails[0].lastName}`}
            </div>
             <div class={`rounded-full lg:py-2 py-4 text-[10px] lg:text-xs px-6  font-bold  mr-7`}>
              <div>{items.order.timestamp}</div>
            </div> 
           
           
            
            <div className="text-blue-500 lg:py-0 py-4 font-bold mr-6 flex">
              Watch Details
            </div>
            <div className="text-blue-500 text-lg">
              <svg
                width={8}
                aria-hidden="true"
                focusable="false"
                data-prefix="far"
                data-icon="angle-right"
                className="svg-inline--fa fa-angle-right fa-w-6 "
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 192 512"
              >
                <path
                  fill="currentColor"
                  d="M187.8 264.5L41 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 392.7c-4.7-4.7-4.7-12.3 0-17L122.7 256 4.2 136.3c-4.7-4.7-4.7-12.3 0-17L24 99.5c4.7-4.7 12.3-4.7 17 0l146.8 148c4.7 4.7 4.7 12.3 0 17z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      {/* {totalQty >0? */}
       <OfferDetails
        opened={opened}
        close={close}
        validStatuses={validStatuses}
        handleUpdate={handleUpdate}
        order={orders}
        userId={items?.user?._id}
        getMyDetails={items?.user?.Mydetails[0]}
        isAdmin={true}
      />
      {/* :<></>*/}
      
    </div> 
    {/*: <></>*/}
    </>
  )
}

export default AdminOffer
