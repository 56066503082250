// For Developers
// For Developers
// For Developers
// For the Next Developer Who is going to work on this project.
// This client given us 1 brief description of the project, since it looks small just agreed on his offer
// $50. But later undrestood, He has no clear objective of the project. He is just adding features as he goes.
// Later we increased it $150. But still he is adding features. He is not clear about the project.
// I had to work on this for 250+ hours for $200. I am not happy with the client. I am not happy with the project.
// We had finsihed it someway because to save our Fivver account.
// Best Wishes for the next developer who is going to work on this project.
// For Developers
// For Developers
// For Developers







import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import App from "./App.jsx";
import "./index.css";
import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Blog from "./Pages/Blog";
import BlogDetail from "./Pages/BlogDetail";
import BlogList from "./Pages/BlogList";
import Product from "./Pages/Product";
import Basket from "./Pages/Basket.jsx";
import Header from "./componet/Header.jsx";
import Footer from "./componet/Footer.jsx";
import HowWrokPage from "./componet/HowWrokPage.jsx";
import SuccessPage from "./Pages/SuccessPage.jsx";
import Dashboard from "./Pages/Dashboard.jsx";
import Appceptance from "./Pages/Acceptance.jsx";
import Terms from "./Pages/Terms.jsx";
import Packaging from "./Pages/Packaging.jsx";
import AboutUs from "./Pages/AboutUs.jsx";
import Contact from "./Pages/Contact.jsx";
import Details from "./Pages/Details.jsx";
import SignUpForm from "./Pages/Signin.jsx";
import Login from "./Pages/Login.jsx";
import Admin from "./Pages/Admin.jsx";
import PrivacyStatement from "./Pages/PrivacyStatement.jsx";
import PasswordReset from "./Pages/PasswordReset.jsx/PasswordReset.jsx";
import ChangePassword from "./componet/ChangePassword.jsx";
// import AdminDashboard from "./Pages/AdminDashboard.jsx";
import UserBlog from "./Pages/UserBlog.jsx";
import BlogEdit from "./Pages/BlogEdit.jsx";
import UserManagement from "./Pages/UserManagement.jsx";
import UserCategory from "./Pages/UserCategory.jsx";
import UserComments from "./Pages/UserComments.jsx";
import Conditions from "./Pages/Conditions.jsx";
import AdminRoute from "./AdminRoute.jsx";
import ReactGA from "react-ga4";
import CustomerOffer from "./customerOffer.jsx";
import AddDiscountForm from "./Pages/AddDiscountForm.jsx";
import baseUrl from "./context/baseUrl.js";
import Cart from "./Pages/Cart.jsx";


// const adminView = localStorage.getItem("adminView");
const TRACKING_ID = "G-322ELRCXBL";
ReactGA.initialize(TRACKING_ID);

function AppRouter() {
  // get the user info from local storage when the app starts
  const [userInfo, setUserInfo] = useState(()=>{
    return localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
  })
  const [cart, setCart] = useState(() => {
    return localStorage.getItem("cart") ? 
    JSON.parse(localStorage.getItem("cart")) : 
    {
      userId : userInfo?.userId,
      items: [],
      total: 0,
      totalQty: 0
    }
  });

  const [isLoading, setIsLoading] = useState(false);
  // console.log(userInfo);
  useEffect(()=>{
    const getCart = async () => {
      try{
        setIsLoading(true);
        const response = await fetch(`${baseUrl}/cart/${userInfo?.userId}`,{
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${userInfo?.token}`,
          },
        }
        ); 
        if(response.status === 200){
          const cartData = await response.json();
        
          localStorage.setItem("cart", JSON.stringify(cartData));
          setCart(cartData);
        }else{
          // alert("Could not fetch the cart data.");
        }
      }catch(error){
        console.error("An error occurred:", error);
        
      }finally{
        setIsLoading(false);
      }
    }
 
    if(userInfo){
      // get the cart data from the backend when the user is logged in

      getCart();
    }else{
      // get the cart data from local storage when the user is not logged in
      const localCart = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : { items: [], total : 0, totalQty: 0 };
      setCart(localCart);
    }

  },[ userInfo]);
  

  const deleteCart = async (id) => {
    try {
      const response = await fetch(`${baseUrl}/cart/${userInfo?.userId}/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "user-id": userInfo?.userId,
        }
      });
      if (response.status === 200) {
        // Re-fetch the updated cart data from the backend
        const updatedCart = await fetchCartData();
        setCart(updatedCart);
      } else {
        alert("Could not update the cart.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };



  const updateCart = async (product) => {
    try {
      const response = await fetch(`${baseUrl}/cart`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "user-id": userInfo?.userId,
        },
        body: JSON.stringify({ product }),
      });
  
      if (response.status === 200) {
        // Re-fetch the updated cart data from the backend
        const updatedCart = await fetchCartData();
        setCart(updatedCart);
      } else {
        alert("Could not update the cart.");
      }
    } catch (error) {
      console.log(error);
      alert("Could not update the cart.");
    }
  };
  
  // Function to fetch the latest cart data from the backend
  const fetchCartData = async () => {
    try {
      const response = await fetch(`${baseUrl}/cart/${userInfo?.userId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const cartData = await response.json();
      return cartData;
    } catch (error) {
      console.error("Error fetching cart data:", error);
      return null;
    }
  };

  function Layout({ children }) {
    const location = useLocation();
  
    return (
      <div className="flex min-h-screen flex-col">
        <Header userInfo={userInfo} cart={cart} />
        {children}
        {location.pathname !== '/' && <Footer />}
      </div>
    );
  }

 
  return (
    <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<App />} />        {/*checked*/}
            <Route path="/signup" element={<SignUpForm userInfo={userInfo} />} />  {/*Checked */}
            <Route path="/login" element={<Login setCart={setCart} cart={cart} setUserInfo={setUserInfo} userInfo={userInfo} />} />  {/*Checked */}
            <Route path="/product" element={<Product userInfo={userInfo} />} />   {/*Checked */}
            <Route path="/selling-basket" element={<Basket cart={cart} userInfo={userInfo} setCart={setCart} />} />  {/*Checked */}
            <Route path="/your-basket" element={<Cart isLoading={isLoading} cart={cart} deleteCart={deleteCart} updateCart={updateCart} userInfo={userInfo} />} /> {/*Checked */}
            <Route path="/check-your-details" element={<Details isLoading={isLoading} cart={cart} userInfo={userInfo} setCart={setCart} />} />  {/*Checked */}
            <Route path="/success" element={<SuccessPage userInfo={userInfo} />} />   {/*Checked */}
            <Route path="/how-it-works" element={<HowWrokPage />} />  {/*Checked */}
            <Route path="/conditions" element={<Conditions />} /> {/*Checked */}
            <Route path="/terms-and-conditions" element={<Terms />} /> {/*Checked */}
            <Route path="/acceptance-guidelines" element={<Appceptance />} /> {/*Checked */}
            <Route path="/packaging-guidelines" element={<Packaging />} /> {/*Checked */}
            <Route path="/about" element={<AboutUs />} /> {/*Checked */}
            <Route path="/contact" element={<Contact />} /> {/*Checked */}
            <Route path="/privacy-statement" element={<PrivacyStatement />} /> {/*Checked */}
            <Route path="/forgot-password/:id/:token" element={<PasswordReset />} />  {/*Checked */}
            <Route path="/forgot-password" element={<ChangePassword userInfo={userInfo} />} /> {/*Checked */}
            <Route path="/my-account" element={<Dashboard userInfo={userInfo} setUserInfo={setUserInfo} setCart={setCart} />} />  {/*Checked */}
            <Route path="/AdminDashboard" element={ userInfo?.admin ==="admin" ? <AdminRoute userInfo={userInfo} /> : <Navigate to="/" />} /> {/*Checked */}
            <Route path="/Admin/" element={ userInfo?.admin ==="admin"  ? <Admin userInfo={userInfo} /> : <Navigate to="/" />} />   {/*Checked*/}
            <Route path="/customeroffers"  element={ userInfo?.admin ==="admin" ? <CustomerOffer userInfo={userInfo} /> : <Navigate to="/"  />} />  {/*Checked */}
            <Route path="/add-discount-details" element={ userInfo?.admin ==="admin" ? <AddDiscountForm /> : <Navigate to="/"  />} />
            <Route path="/blogs" element={<Blog />} />
            <Route path="/blogdetails" element={<BlogDetail userInfo={userInfo} />} />
            <Route path="/bloglist" element={<BlogList />} />
            <Route path="/userblogs" element={ userInfo?.admin ==="admin" ? <UserBlog userInfo={userInfo} /> : <Navigate to="/" />} />
            <Route path="/blogedit" element={ userInfo?.admin ==="admin" ? <BlogEdit userInfo={userInfo} /> : <Navigate to="/" />} />
            <Route path="/usermanagement" element={ userInfo?.admin ==="admin" ? <UserManagement /> : <Navigate to="/" />} />
            <Route path="/usercategory" element={ userInfo?.admin ==="admin" ? <UserCategory /> : <Navigate to="/" />} />
            <Route path="/usercomments" element={ userInfo?.admin ==="admin" ? <UserComments /> : <Navigate to="/" />} />
          </Routes>
        </Layout>
    </BrowserRouter>
  )
}



ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <ToastContainer />
    <AppRouter />
  </React.StrictMode>
);
