import { Modal, Select } from "@mantine/core"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { degrees, PDFDocument, rgb, StandardFonts } from "pdf-lib"
import download from "downloadjs"
import { useDisclosure } from "@mantine/hooks"
import CryptoJS from 'crypto-js';
import baseUrl from "../context/baseUrl"

const Adminorder = ({  user, userInfo }) => {
  const [OrderOpen, setOrderOpen] = useState()
  const [Status, setStatus] = useState("pending")
  const [orderId, setOrderId] = useState()
  // const [userId, setuserId] = useState()
  const userId = user?._id;
  const orders = user?.Order;
  const userDetails = user?.Mydetails[0];
  // console.log("orders", orders)
  const getStatus = (a) => {
    switch (a) {
      case 'Pending':
        return 'bg-yellow-100 text-yellow-800';
      case 'Received':
        return 'bg-blue-100 text-blue-800';
      case 'Checking':
        return 'bg-purple-100 text-purple-800';
      case 'Accepted':
        return 'bg-green-100 text-green-800';
      case 'Rejected':
        return 'bg-red-100 text-red-800';
      case 'Paid':
        return 'bg-green-100 text-green-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };
  // useEffect(() => {
  //   handleUpdate()
  // }, [props.userInfo?.userId, Status, Status])
  const handleUpdate = () => {
    axios
      .put(`${baseUrl}/Getorder/status/${userId}`, {
        Status,
        orderId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${userInfo.token}`,
        },
      }
      )
      .then((response) => {
        window.location.reload()
        // console.log("Data updated:", response.data)
        // Handle successful update
      })
      .catch((error) => {
        console.error("Error updating data:", error)
        // Handle error
      })
  }

  const [opened, { open, close }] = useDisclosure(false)

  const handleDeleteAccount = async () => {
    // console.log(email)
    const email = user?.email
    // Data to encrypt
    const sensitiveData = 'frontend';

    // Encryption key (must be a secret)
    const encryptionKey = 'legotwosell';

    // Encrypt the data
    const encryptedData = CryptoJS.AES.encrypt(sensitiveData, encryptionKey).toString();

    try {
      const response = await fetch(`${baseUrl}/delete-account`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "source": encryptedData,
          "user-id": userInfo.userId,
          "Authorization": `Bearer ${userInfo?.token}`,

        },
        body: JSON.stringify({ email }),
      })
      window.location.reload()
      const data = await response.json()
      console.log(data)
      if (response.ok) {
        // setMessage(data.message)
        // setEmail("")
      } else {
        // setMessage(data.message)
      }
    } catch (error) {
      console.error(error)
      // setMessage("Internal Server Error")
    }
    close()
  }

  const [offerIdPdf, setOfferIdPdf] = useState()
  const [offerIdTime, setOfferIdTime] = useState()
  // console.log(offerIdPdf)
  const handleModifyPdf = async () => {
    const url = "/completpdf.pdf"

    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer())

    const pdfDoc = await PDFDocument.load(existingPdfBytes)
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold)

    const pages = pdfDoc.getPages()
    const firstPage = pages[1]
    const { width, height } = firstPage.getSize()
    firstPage.drawText(`${offerIdTime}`, {
      x: 220,
      y: 240,
      size: 18,
      font: helveticaFont,
      color: rgb(0, 0, 0),
      rotate: degrees(0),
    })
    const second = pages[1]
    const { width1, height1 } = second.getSize()
    firstPage.drawText(`#${offerIdPdf}`, {
      x: 220,
      y: 200,
      size: 18,
      font: helveticaFont,
      color: rgb(0, 0, 0),
      rotate: degrees(0),
    })

    const pdfBytes = await pdfDoc.save()

    download(pdfBytes, "lego2sellPDF.pdf", "application/pdf")
  }
  // console.log("apiValue", data[0]?.Marketingpreferences)
 
  const filteredOrders = user?.Order?.filter((value) => {
    return value.Status !== "Paid" && value.Status !== "Rejected"
  }).map((value, index) => {
    return <div key={index}>{value.Status}</div>
  })
  return (
    <div className="py-2">
      <div
        onClick={() => setOrderOpen(!OrderOpen)}
        className="last:mb-0  duration-300 bg-white shadow-[0_4px_20px_rgba(0,0,0,0.15)] rounded-2xl py-6 px-8 cursor-pointer"
      >
        <div className="flex flex-wrap items-center justify-between">
          <div className="mr-auto lg:py-0 py-4 font-medium lg:w-1/4">
            Account ID: # {userId?.slice(0, 6)}
            <br className="md:hidden" />
          </div>
          <div className="mr-auto lg:py-0 py-4 text-xs font-medium lg:w-1/4">
            Email Id : {user?.email}
            <br className="md:hidden" />
          </div>
          <div className="mr-auto lg:py-0 py-4 font-medium lg:w-1/4">
            {`    ${userDetails?.title}
            ${userDetails?.firstName}
            ${userDetails?.lastName}`}
          </div>
          <div className="text-blue-500 lg:py-0 py-4 font-bold mr-1 flex lg:w-1/5 justify-self-end">
            Watch Details
          
          <div className="text-blue-500 text-lg">
            <svg
              width={8}
              aria-hidden="true"
              focusable="false"
              data-prefix="far"
              data-icon="angle-right"
              className="svg-inline--fa fa-angle-right fa-w-6 "
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 192 512"
            >
              <path
                fill="currentColor"
                d="M187.8 264.5L41 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 392.7c-4.7-4.7-4.7-12.3 0-17L122.7 256 4.2 136.3c-4.7-4.7-4.7-12.3 0-17L24 99.5c4.7-4.7 12.3-4.7 17 0l146.8 148c4.7 4.7 4.7 12.3 0 17z"
              />
            </svg>
          </div>
          </div>
        </div>
      </div>
      {OrderOpen && (
        <div className="bg-white duration-300 mt-4 shadow-[0_4px_20px_rgba(0,0,0,0.15)] rounded-2xl">
          <button
            onClick={open}
            className="flex ml-auto px-6 py-6 items-center justify-between"
          >
            <div className=""></div>
            <svg
              width={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M10 12V17"
                  stroke="#000000"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>{" "}
                <path
                  d="M14 12V17"
                  stroke="#000000"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>{" "}
                <path
                  d="M4 7H20"
                  stroke="#000000"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>{" "}
                <path
                  d="M6 10V18C6 19.6569 7.34315 21 9 21H15C16.6569 21 18 19.6569 18 18V10"
                  stroke="#000000"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>{" "}
                <path
                  d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z"
                  stroke="#000000"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>{" "}
              </g>
            </svg>
          </button>
          <Modal opened={opened} onClose={close} title="Authentication">
            {/* Modal content */}
            <h4>Delete Account</h4>
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button
                type="button"
                className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                onClick={handleDeleteAccount}
              >
                Delete
              </button>
              <button
                onClick={close}
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
              >
                Cancel
              </button>
            </div>
          </Modal>
          <div className="px-6 my-4 py-4 rounded-xl">
            <div className="">
              <div className="flex items-center py-1 gap-4">
                <h3 className="text-base font-semibold">Title:</h3>
                <h6 className="text-base">{userDetails?.title}</h6>
              </div>
              <div className="flex items-center py-1 gap-4">
                <h3 className="text-base font-semibold">First Name:</h3>
                <h6 className="text-base">{userDetails?.firstName}</h6>
              </div>
              <div className="flex items-center py-1 gap-4">
                <h3 className="text-base font-semibold">Last Name:</h3>
                <h6 className="text-base">{userDetails?.lastName}</h6>
              </div>

              <div className="flex items-center py-1 gap-4">
                <h3 className="text-base font-semibold">Email:</h3>
                <h6 className="text-base line-clamp-1 ">{userDetails?.email} </h6>
              </div>
              <div className="flex items-center py-1 gap-4">
                <h3 className="text-base font-semibold">Telephone:</h3>
                <h6 className="text-base">{userDetails?.Telephone} </h6>
              </div>
              <div className="flex items-center py-1 gap-4">
                <h3 className="text-base font-semibold">Street Address-1:</h3>
                <h6 className="text-base">{userDetails?.StreetAddress1} </h6>
              </div>
              <div className="flex items-center py-1 gap-4">
                <h3 className="text-base font-semibold">Street Address-2:</h3>
                <h6 className="text-base">{userDetails?.StreetAddress2} </h6>
              </div>
              <div className="flex items-center py-1 gap-4">
                <h3 className="text-base font-semibold">City :</h3>
                <h6 className="text-base">{userDetails?.city} </h6>
              </div>
              <div className="flex items-center py-1 gap-4">
                <h3 className="text-base font-semibold">Country :</h3>
                <h6 className="text-base">{userDetails?.Country} </h6>
              </div>
              <div className="flex items-center py-1 gap-4">
                <h3 className="text-base font-semibold">Postcode:</h3>
                <h6 className="text-base">{userDetails?.Postcode} </h6>
              </div>
              <div className="flex items-center py-1 gap-4">
                <h3 className="text-base font-semibold">Payment Method:</h3>
                <h6 className="text-base">{userDetails?.paymentMethod} </h6>
              </div>
              <div className="flex items-center py-1 gap-4">
                <h3 className="text-base font-semibold">Account Number:</h3>
                <h6 className="text-base">{userDetails?.accountNumber} </h6>
              </div>
              <div className="flex items-center py-1 gap-4">
                <h3 className="text-base font-semibold">Sort Code:</h3>
                <h6 className="text-base">
                  {`${userDetails?.sortCode1} - ${userDetails?.sortCode2} - ${userDetails?.sortCode3} `}
                </h6>
              </div>
              <div className="flex items-center py-1 gap-4">
                <h3 className="text-base font-semibold">Paypal Email :</h3>
                <h6 className="text-base">{userDetails?.Paypalemail} </h6>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center gap-4">
            <h5 className="text-xl font-semibold">
              Marketing Preferences
            </h5>
            <h2 className="text-xl bg-blue-500 my-8 text-white px-6 py-1 rounded-full font-medium">
              {userDetails?.Marketingpreferences}
            </h2>
          </div>
          {orders.length > 0 && (
              orders.map((order,index) => {
                return (<OrderDetails key={index} paymentMethod={userDetails.paymentMethod} order={order} setOfferIdPdf={setOfferIdPdf} setOfferIdTime={setOfferIdTime} getStatus={getStatus} />)}
            ))}
          
        </div>
      )}
    </div>
  )
}

const OrderDetails = ({paymentMethod, order, setOfferIdPdf, setOfferIdTime, getStatus }) => {
  const validStatuses = ["Pending", "Received", "Checking", "Accepted", "Rejected", "Paid"];
  const validStatusesRejected = ["Pending", "Received", "Checking", "Accepted", "Paid"];
  
  const total = order?.orderItems?.filter((item) => { 
    return validStatusesRejected.includes(item?.Status);
  })?.map((item) => item?.subTotal).reduce((a, b) => a + b, 0);

  const totalQty = order?.orderItems?.filter((item) => {
    return validStatusesRejected.includes(item?.Status);
  })?.map((item) => item?.qty).reduce((a, b) => a + b, 0);

  const bonus = order?.discount >0 ? total * order?.discount/100 : 0;
  const finalTotal = total + bonus;
  return(
    <div className="flex flex-col justify-between mx-3 border-teal-200 mt-12">
      <div className="flex flex-col items-center justify-center py-2 text-blue-500 border-b-lime-500 ">
        <h2>Offer ID: #{order?.offerId}</h2> 
        <h2> What you will be sending to us.</h2>
        <hr className="mt-4" />
      </div>
      <div className="flex flex-row items-center justify-between py-2 text-blue-500 border-b-lime-500 ">
        <div className="flex flex-col ">
          <h2>Date &amp; Time: <span>{order?.timestamp}</span> </h2  >
          {/* <h2> Delivery methods: <span>{order?.Deliverymethod}</span> </h2>                   */}
        </div>
        <div className="flex flex-col ">
          <h2>Payment Method: {paymentMethod}</h2>          
          <h2> No. of items: {totalQty}</h2>          
        </div>             
      </div>    
      <div className="overflow-x-auto">
        <table className="min-w-full"> 
          <thead>
            <tr>
            <th className="py-2 px-4 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">Product Name</th>
            <th className="py-2 px-4 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">Product ID</th>
            <th className="py-2 px-4 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">Condition</th>
            <th className="py-2 px-4 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">Status</th>
            <th className="py-2 px-4 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">Quantity</th>
            <th className="py-2 px-4 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">Offer Price</th>
            <th className="py-2 px-4 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">Sub Total Price</th>
            </tr>
          </thead>

          <tbody>
          {order.orderItems?.filter((item) => { 
                    return validStatuses.includes(item.Status);
                  })?.map((item, index) => (
                            
                                <tr key={index}>
                                    <td className="py-2 px-4 border-b border-gray-200">{item?.name}</td>
                                    <td className="py-2 px-4 border-b border-gray-200">{item?.productId}</td>
                                    <td className="py-2 px-4 border-b border-gray-200">{item?.condition}</td>
                                    <td className={`py-2 px-2 border-b border-gray-200 ${getStatus(item.Status)}`}>{item?.Status}</td>
                                    <td className="py-2 px-4 border-b border-gray-200">{item?.qty}</td>
                                    <td className="py-2 px-4 border-b border-gray-200">£{item?.price?.toFixed(2)}</td>
                                    <td className="py-2 px-4 border-b border-gray-200">£{item?.subTotal?.toFixed(2)}</td>
                                </tr>
                            ))}
                            <tr >
                                    <td className="py-2 px-4 "></td>
                                    <td className="py-2 px-4 "></td>
                                    <td className="py-2 px-4 "></td>
                                    <td className="py-2 px-2 "></td>
                                    <td className="py-2 px-4 "></td>
                                    <td className="py-2 px-4 border-b border-gray-200">Total Price</td>
                                    <td className="py-2 px-4 border-b border-gray-200">£{order?.totalPrice?.toFixed(2)}</td>

                                </tr>

                               {bonus>0 ?<tr >
                                    <td className="py-2 px-4 "></td>
                                    <td className="py-2 px-4 "></td>
                                    <td className="py-2 px-4 "></td>
                                    <td className="py-2 px-2 "></td>
                                    <td className="py-2 px-4 "></td>
                                    <td className="py-2 px-4 border-b border-gray-200">Voucher {order.discount}%</td>
                                    <td className="py-2 px-4 border-b border-gray-200">£{bonus?.toFixed(2)}</td>

                                </tr> : null  }
                                
                                <tr >
                                    <td className="py-2 px-4 "></td>
                                    <td className="py-2 px-4 "></td>
                                    <td className="py-2 px-4 "></td>
                                    <td className="py-2 px-2 "></td>
                                    <td className="py-2 px-4 "></td>
                                    <td className="py-2 px-4 border-b border-gray-200">Final Price</td>
                                    <td className="py-2 px-4 border-b border-gray-200">£{finalTotal?.toFixed(2)}</td>

                                </tr>                             
          </tbody>
        </table>
      </div>
      

                    
      
    </div>
  )
}

export default Adminorder
