import {
  Checkbox,
  Divider,
  Group,
  Modal,
  Radio,
  Select,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CountryCity from "./Country";
import baseUrl from "../context/baseUrl";

const Editdetails = ({ close, setSidebarActive, opened, data, userInfo, reNewData }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatpassword, setRepeatpassword] = useState();
  const navigation = useNavigate();
  const location = useLocation();
  const [PaymentDetails, setPaymentDetails] = useState("Paypal");
  const [searchValue, onSearchChange] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedState, setSelectedState] = useState("");

  const form = useForm({
    initialValues: {
      email: data?.email || "",
      paymentMethod: PaymentDetails,
      firstName: data?.firstName || "",
      lastName: data?.lastName || "",
      Telephone: data?.Telephone || "",
      title: data?.title || "",
      StreetAddress1: data?.StreetAddress1 || "",
      termsOfService: true,
      StreetAddress2: data?.StreetAddress2 || "",
      city: data?.city || "",
      State: selectedState || "",
      Country: selectedCountry || "",
      Paypalemail: data?.Paypalemail || "",
      accountNumber: data?.accountNumber || "",
      sortCode1: data?.sortCode1 || "",
      sortCode2: data?.sortCode2 || "",
      sortCode3: data?.sortCode3 || "",
      TermsCheck: data?.TermsCheck || false,
      Marketingpreferences: "",
      Postcode: data?.Postcode || "",
    },

    validate: {
      firstName: (value) =>
        value.length < 2 ? "First Name must have at least 2 letters" : null,
      lastName: (value) =>
        value.length < 2 ? "Last Name must have at least 2 letters" : null,
      title: (value) =>
        value.length < 2 ? "Title must have at least 2 characters" : null,
      Telephone: (value) =>
        value.length < 2 ? "Telephone must have at least 2 characters" : null,
      StreetAddress1: (value) =>
        value.length < 2 ? "Street Address1 must have at least 2 characters" : null,
      StreetAddress2: (value) =>
        value.length < 2 ? "Street Address2 must have at least 2 characters" : null,
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });

  useEffect(() => {
    form.setValues({
      email: data?.email || "",
      firstName: data?.firstName || "",
      lastName: data?.lastName || "",
      Telephone: data?.Telephone || "",
      title: data?.title || "",
      StreetAddress1: data?.StreetAddress1 || "",
      StreetAddress2: data?.StreetAddress2 || "",
      city: data?.city || "",
      State: selectedState || "",
      Country: selectedCountry || "",
      Paypalemail: data?.Paypalemail || "",
      accountNumber: data?.accountNumber || "",
      sortCode1: data?.sortCode1 || "",
      sortCode2: data?.sortCode2 || "",
      sortCode3: data?.sortCode3 || "",
      Postcode: data?.Postcode || "",
    });
  }, [data, selectedState, selectedCountry]); // Dependencies that trigger useEffect

  const handleSubmit = async (values) => {
    try {
      // const response3 = await axios.put(
      //   `${baseUrl}/update-email/${userInfo?.userId}`,
      //   {
      //     newEmail: form.values.email,
      //   },
      //   {
      //     headers: { Authorization: `Bearer ${userInfo?.token}` },
      //   }
      // );

      // const newToken = response3?.data?.token;
      // setUserInfo({ ...userInfo, token: newToken });

      const payload = {
        email: form.values.email,
        paymentMethod: PaymentDetails,
        firstName: form.values.firstName,
        lastName: form.values.lastName,
        Telephone: form.values.Telephone,
        title: form.values.title,
        StreetAddress1: form.values.StreetAddress1,
        termsOfService: form.values.termsOfService,
        StreetAddress2: form.values.StreetAddress2,
        city: form.values.city,
        State: selectedState,
        Country: selectedCountry,
        Paypalemail: form.values.Paypalemail,
        accountNumber: form.values.accountNumber,
        sortCode1: form.values.sortCode1,
        sortCode2: form.values.sortCode2,
        sortCode3: form.values.sortCode3,
        TermsCheck: form.values.TermsCheck,
        Marketingpreferences: form.values.TermsCheck,
        Postcode: form.values.Postcode,
      };

      const response1 = await axios.post(
        `${baseUrl}/MyDetails/${userInfo?.userId}`,
        payload, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      }
      ).then((response) => {
        console.log(response);
        if (response.status === 200) {
          reNewData();
          alert("Details Updated Successfully");
          
          close();
        }
      });
      
      setSidebarActive(2);
      window.scrollTo({ top: 0, behavior: "smooth" });
      
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <Modal size={"xl"} opened={opened} onClose={close} title="Edit MyDetails">
      <form method="post" onSubmit={form.onSubmit(handleSubmit)}>
        <div className="mt-24 px-6 lg:px-12">
          <h3 className="text-2xl font-bold">My Details</h3>
          <div className="py-3">
            <Select
              {...form.getInputProps("title")}
              withAsterisk
              label="Title"
              placeholder="Pick one"
              searchable
              nothingFound="No options"
              data={["Dr", "Miss", "Mr", "Mrs", "Ms", "Rev", "Sir"]}
            />
          </div>
          <div class="py-3">
            <TextInput
              type="text"
              withAsterisk
              label="First Name"
              placeholder="First Name"
              {...form.getInputProps("firstName")}
            />
          </div>
          <div class="py-3">
            <TextInput
              withAsterisk
              label="Last Name"
              placeholder="Last Name"
              {...form.getInputProps("lastName")}
            />
          </div>
          <div class="py-3">
            <TextInput
              type="email"
              withAsterisk
              label="Email"
              placeholder="your@email.com"
              // {...form.getInputProps("email")}
              defaultValue={data?.email}
            />
          </div>
          <div class="py-3">
            <TextInput
              label="Telephone"
              placeholder="Telephone"
              {...form.getInputProps("Telephone")}
            />
          </div>

          <div className="py-4">
            <h1 className="text-2xl font-bold">Address Details</h1>
            <p className="text-gray-500 py-1">
              Please enter your address details below.
            </p>
            <div className="">
              <div className="">
                <div class="py-3">
                  <TextInput
                    withAsterisk
                    label="Street Address1"
                    placeholder="StreetAddress1"
                    {...form.getInputProps("StreetAddress1")}
                  />
                </div>
                <div class="py-3">
                  <TextInput
                    withAsterisk
                    label="Street Address2"
                    placeholder="StreetAddress2"
                    {...form.getInputProps("StreetAddress2")}
                  />
                </div>
                <div class="py-3">
                  <TextInput
                    label="City"
                    required
                    withAsterisk
                    name="city"
                    {...form.getInputProps("city")}
                    placeholder="Enter a City"
                  />
                </div>
                <div className="py-3">
                  <CountryCity
                    setSelectedState={setSelectedState}
                    setSelectedCity={setSelectedCity}
                    setSelectedCoutry={setSelectedCountry}
                    form={form}
                  />
                </div>
                <TextInput
                  withAsterisk
                  label="Postcode"
                  placeholder="Enter a Postcode"
                  {...form.getInputProps("Postcode")}
                />
              </div>
            </div>
            <Divider className="my-4" />
            <h1 className="text-2xl font-bold">Payment Details</h1>
            <Group mt="xs">
              <Radio
                label="Paypal"
                value="Paypal"
                onClick={() => setPaymentDetails("Paypal")}
                checked={PaymentDetails === "Paypal"}
              />
              <Radio
                label="Bank Transfer"
                value="Bank Transfer"
                onClick={() => setPaymentDetails("Bank Transfer")}
                checked={PaymentDetails === "Bank Transfer"}
              />
            </Group>
            <div className="my-4">
              {PaymentDetails === "Paypal" ? (
                <TextInput
                  withAsterisk
                  label="Paypal Email"
                  {...form.getInputProps("Paypalemail")}
                  placeholder="Paypal email"
                />
              ) : (
                <div>
                  <div class="py-3">
                    <TextInput
                      withAsterisk
                      label="Account Number"
                      placeholder="Account Number"
                      {...form.getInputProps("accountNumber")}
                    />
                  </div>
                  <div class="py-3">
                    <TextInput
                      label="Sort Code 1"
                      placeholder="Sort Code 1"
                      {...form.getInputProps("sortCode1")}
                    />
                  </div>
                  <div class="py-3">
                    <TextInput
                      label="Sort Code 2"
                      placeholder="Sort Code 2"
                      {...form.getInputProps("sortCode2")}
                    />
                  </div>
                  <div class="py-3">
                    <TextInput
                      label="Sort Code 3"
                      placeholder="Sort Code 3"
                      {...form.getInputProps("sortCode3")}
                    />
                  </div>
                  <div className="rounded-xl bg-[#F8F8FE] p-4 text-sm text-blue-500 text-center mt-4">
                  We'll pay directly to your account the{" "}
                  <strong className="font-bold">same day</strong> we Accept your
                  LEGO®.
                </div>
                </div>
              )}
            </div>
          </div>
           
        </div>
        <div class="flex w-full">
          <button
            type="submit"
            className="bg-blue-500 text-white rounded-xl h-[80px] px-16 mx-auto my-8 inline-flex items-center justify-center font-bold text-lg "
          >
            Confirm
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default Editdetails;
