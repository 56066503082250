import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Checkbox, Loader } from "@mantine/core";
import axios from "axios";
// import { Country, State, City } from "country-state-city";
import { Helmet } from "react-helmet";
import baseUrl from "../context/baseUrl";
import Item from "./Item";

const Summary = (props) => {
  const cart = props.cart;
  const isLoading = props.isLoading;
  const setCart = props.setCart;
  const [haveVoucher, setHaveVoucher] = useState(false);
  const [voucherApplied, setvoucherApplied] = useState(false);
  const [voucher, setVoucher] = useState("");
  const [extra, setExtra] = useState(0);
  const [bonus, setBonus] = useState(0);
  const [final, setFinal] = useState(0);

  

  const navigate = useNavigate();
  const [sendMethod, setSendMethod] = useState();
  const [acceptOffer, setAcceptOffer] = useState();
  const [details, setDetails] = useState();
  // console.log(details, "demo111111")
  const [data, setData] = useState(false);
  const [dashPattern, setDashPattern] = useState();
  const productDetails = props.productDetails;
  // console.log(new Date().toDateString())
  // console.log("cart", cart)
  const payload = cart;
  payload.Deliverymethod = sendMethod;
  const [orderitems, setOrderitems] = useState();
  // console.log(props.productDetails, "productDetails")
  // console.log("demo111", orderitems)

  useEffect(() => {
    
    // console.log("prevPrice", prevPrice)
  }, [props.productDetails]);
  useEffect(() => {
    async function fetchOrders() {
      try {
        const response = await axios.get(
          `${baseUrl}/Getorder/${props.userInfo?.userId}`
        );
        setOrderitems(response.data);

      } catch (error) {
        // setError("Error fetching orders")
        // console.log("Error fetching orders")
      }
    }

    fetchOrders();
  }, [props.userInfo]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/Mydetails/${props.userInfo?.userId}`
        );
        setDetails(response.data.Mydetails[0]);
        const response1 = await fetch(`${baseUrl}/find-lego`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ itemCode: props.SearchValue }),
        });

        const data = await response1.json();
        // console.log("Data", data)
        // localStorage.setItem("data", data)
        if (data.message === "SUCCESS") {
          setData(data);
          
        } else {
          // console.log("error")
          // alert("Could not find the LEGO you are looking for.")
        }
      } catch (error) {
        console.error("An error occurred:", error);
        // Handle the error as needed
      }
    };

    fetchData();
  }, [props.SearchValue, props.userInfo]);

  

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await axios.post(
        `${baseUrl}/Getorder/${props.userInfo?.userId}`,
        payload
      );
      const offerId = response.data.offerId;
      setCart({ items: [], total: 0, totalQty: 0 });
      const SearchValue = props.SearchValue;
      const condition = props.productCondition;
      navigate("/success", {
        state: { data, productDetails, SearchValue, condition, offerId },
      });
      // localStorage.setItem("Basket", "")
      window.scrollTo({ top: 0, behavior: "smooth" });
      // console.log("workingsdsd", response.data.offerId)
    } catch (error) {
      console.error(error);
    }

    if (window.innerWidth <= 768) {
      // Scroll to the top of the page with a smooth animation
      if (window.innerWidth <= 768) {
        // Scroll to the top of the page using the scrollTo() method
        window.scrollTo(0, 0);
      }
    }
  };

  const applyVoucher = async () => {
    try {
      const response = await fetch(`${baseUrl}/discount/${voucher}`);
      const voucherData = await response.json();
      
      if (response.status === 200 && new Date(voucherData.expirationDate) > new Date() && new Date(voucherData.startDate) < new Date()) {
        const temp = cart.total * (voucherData.amount / 100);
        
        const final = cart.total + temp;
        setExtra(voucherData.amount);
        // payload.voucherInfo = voucherInfo;
        payload.voucher = voucher;
        setFinal(final);
        setBonus(temp);
        setvoucherApplied(true);
        console.log("voucher", payload)
      }else{
        alert("Invalid voucher code")
      }
      
    }catch(error){
      alert("Invalid voucher code")
      console.error(error)
    }
    
  }



  // const handleSearch = async () => {
  //   try {
  //     const response = await fetch(`${baseUrl}/find-lego`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ itemCode: props.SearchValue }),
  //     });

  //     const data = await response.json();
  //     // console.log("Data", data)
  //     // localStorage.setItem("data", data)
  //     // localStorage.setItem("SearchValue", e)
  //     if (data.message === "SUCCESS") {
  //       setDashPattern(data);
  //     } else {
  //       // console.log("error")
  //       // alert("Could not find the LEGO you are looking for.")
  //     }
  //   } catch {
  //     // console.log("error")
  //     // alert("Could not find the LEGO you are looking for.")
  //   } finally {
  //     // console.log("complete") // Set loading state back to false
  //   }
  // };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Confirm your details| LEGO®</title>

        <meta name="keywords" content="Lego, sell, Lego2Sell, Lego 2 Sell" />
        <meta name="viewport" content="width=device-width" />
        <meta
          property="og:description"
          content="Lego2Sell.com is the quick, convenient, and free online platform to sell your LEGO® sets for cash! Whether you have a complete collection or a mixed assortment of lego, we're here to buy. Start selling your LEGO with Lego2Sell.com today!"
        />
      </Helmet>
      <h1 className="text-4xl py-4 font-bold h1 text-center">Offer Summary</h1>
      <div className="flex flex-col  w-4/5 mx-auto  px-2 lg:px-6  ">
          <div className="px-4 lg:px-6 lg:pt-12 mx-auto w-full ">
            
            <h1 className="text-2xl h3 py-1 font-bold ">
              Please Send Your LEGO® Set(s) to us
            </h1>
            <p className="text-xs mb-3 font-medium text-gray-500">
              (Address Label Will Be Available From Completed Checkout PopUp For
              Download)
            </p>
            <p className="text-xs mb-6 font-medium text-gray-500">
              Purchase Your Postage (refunded up to £2.49 on all sets we accept)
            </p>
            <div className="lg:w-2/4 w-full rounded-xl border gap-8 mb-14">
                <div className="relative w-full rounded-b-lg p-6 py-10">
                  <img
                    className="w-full object-contain h-full"
                    src="/Images/brand.jpeg"
                    alt=""
                  />
                </div>
              </div>
              </div>
            {/* <p className="text-gray-500 py-1">Select your preferred method*</p> */}
            <div className="flex flex-col lg:flex-row w-full justify-between   p-2">
              <div className="w-full lg:w-3/5 m-2 p-3">
                  <div className="flex items-center justify-between">
                    <h1 className="text-2xl h3 font-bold ">Your Details</h1>
                    <button onClick={props.prevStep} className="text-blue-500">
                      Edit
                    </button>
                  </div>
                  {details ? (
                    <div className="border px-6 my-4 py-4 rounded-xl">
                      <div className="flex items-center py-1 gap-4">
                        <h3 className="text-base font-semibold"> Title :</h3>
                        <h6 className="text-base">{details?.title}</h6>
                      </div>
                      <div className="flex items-center py-1 gap-4">
                        <h3 className="text-base font-semibold">First Name:</h3>
                        <h6 className="text-base">{details?.firstName}</h6>
                      </div>
                      <div className="flex items-center py-1 gap-4">
                        <h3 className="text-base font-semibold">Last Name:</h3>
                        <h6 className="text-base">{details?.lastName}</h6>
                      </div>
                      <div className="flex items-center  py-1 gap-4">
                        <h3 className="text-base font-semibold">Email:</h3>
                        <h4 className="text-base line-clamp-4 w-[200px] lg:w-full ">
                          {details?.email}
                        </h4>
                      </div>
                      <div className="flex items-center py-1 gap-4">
                        <h3 className="text-base font-semibold">Telephone:</h3>
                        <h6 className="text-base">{details?.Telephone}</h6>
                      </div>

                      <div className="flex items-center py-1 gap-4">
                        <h3 className="text-base font-semibold">
                          Street Address-1:
                        </h3>
                        <h6 className="text-base">{details?.StreetAddress1}</h6>
                      </div>
                      <div className="flex items-center py-1 gap-4">
                        <h3 className="text-base font-semibold">
                          Street Address-2:
                        </h3>
                        <h6 className="text-base">{details?.StreetAddress2}</h6>
                      </div>
                      <div className="flex items-center py-1 gap-4">
                        <h3 className="text-base font-semibold">City:</h3>
                        <h6 className="text-base">{details?.city}</h6>
                      </div>
                      <div className="flex items-center py-1 gap-4">
                        <h3 className="text-base font-semibold">Country:</h3>
                        <h6 className="text-base">{details?.Country}</h6>
                      </div>
                      <div className="flex items-center py-1 gap-4">
                        <h3 className="text-base font-semibold">Post Code:</h3>
                        <h6 className="text-base">{details?.Postcode}</h6>
                      </div>
                      <div className="flex items-center py-1 gap-4">
                        <h3 className="text-base font-semibold">Paypal Email:</h3>
                        <h6 className="text-base">
                          {details?.Paypalemail ? details?.Paypalemail : "No"}
                        </h6>
                      </div>
                      <div className="flex items-center py-1 gap-4">
                        <h3 className="text-base font-semibold">
                          Account Number:
                        </h3>
                        <h6 className="text-base">
                          {details?.accountNumber ? details?.accountNumber : "No"}
                        </h6>
                      </div>
                      <div className="flex items-center py-1 gap-4">
                        <h3 className="text-base font-semibold">Sort Code:</h3>
                        <h6 className="text-base">
                          {` ${details?.sortCode1 ? details?.sortCode1 : "No"
                          } -   ${details?.sortCode2 ? details?.sortCode2 : "No"
                          } -  ${details?.sortCode3 ? details?.sortCode3 : "No"}`}
                        </h6>
                      </div>

                      <div className="flex items-center py-1 gap-4">
                        <h3 className="text-base font-semibold">
                          Marketing Preferences:
                        </h3>
                        <h6 className="text-base">
                          {details?.Marketingpreferences}
                        </h6>
                      </div>
                      <div className="flex items-center py-1 gap-4">
                        <h3 className="text-base font-semibold">
                          Payment Details:
                        </h3>
                        <h6 className="text-base">{details?.paymentMethod}</h6>
                      </div>
                    </div>
                  ) : (
                    <div className="py-12">
                      <Loader />
                    </div>
                  )}
              
                </div>
            <div className="bg-white lg:w-2/5 m-2 p-2  w-full  rounded-2xl shadow-[0_4px_25px_rgba(38,50,92,0.1)]">
             
              {!isLoading ?<div className="border w-full flex-1 px-4 lg:px-12 border-gray-300 rounded-xl">
            {cart?.items?.length === 0 ? (
            <p>Your cart is empty</p>
            ) : (
            <h2 className="text-xl font-semibold">Your Offer Summary</h2>
            )}

            <div   className="flex flex-col w-full m-auto">
              {/* <div className="flex mb-4 pb-4 border-b-2 items-start">
            <p>Basket</p>
              </div> */}

          {cart?.items?.map((item, index) => (
            <Item
              key={index}
              index={index}
              product={item}
              removeContoller={true}
              // increment={() => increment(item)}
              // decrement={() => decrement(item)}
              // removeProduct={() => removeProduct(item)}
            />
          ))}

          <div className="flex justify-between mx-3">
            <p>Total</p>
            <span className="text-black text-xl font-bold">£{cart?.total?.toFixed(2)}</span>
          </div>
          {voucherApplied? <> 
          <div className="flex justify-between mx-3">
            <p>Extra <span>{extra}%</span> </p>
            <span className="text-black text-xl font-bold">£{bonus?.toFixed(2)}</span>
          </div>
          <div className="flex justify-between mx-3">
            <p>Final</p>
            <span className="text-black text-xl font-bold">£{final?.toFixed(2)}</span>
          </div>
          </>: null }

          
        </div>
      </div>: <Loader size="xs" />}
      <div className="flex flex-col w-5/6 m-auto"> 
        <button
          onClick={() => setHaveVoucher(!haveVoucher)}
          className=" mt-4 w-full text-center lg:ml-0 flex items-center justify-center px-6 lg:px-9 bg-blue-500    text-black font-bold text-[14px] h-[49px] lg:h-[65px]  xl:text-[18px] rounded-xl"
        >
          I have a Voucher code 
        </button>
        <div className={`flex ${haveVoucher ? "block" : "hidden"} mt-4`}>
  <input
    type="text"
    placeholder="Enter Voucher Code"
    value={voucher}
    onChange={(e) => setVoucher(e.target.value)}
    className="w-1/2 border rounded-xl p-2 h-[49px] lg:h-[65px]"
  />
  <button 
    className=" w-1/2 text-center flex items-center justify-center px-6 bg-blue-500 text-black font-bold text-[15px] lg:text-[18px] rounded-xl h-[49px] lg:h-[65px]"
    onClick={applyVoucher}
    >
    Apply Voucher
  </button> 
</div> 
      </div>
      <div className="flex py-4 gap-1 w-5/6 mx-auto">
                <Checkbox onChange={() => setAcceptOffer(!acceptOffer)} />
                 <p>
                  {`I accept the offer of   £${voucherApplied? final?.toFixed(2) : cart?.total?.toFixed(2)} and the `}
                  <span>terms and conditions*</span>
                </p>
              </div>
              <button
                onClick={handleSubmit}
                disabled={!acceptOffer}
                type="button"
                className={`  ${acceptOffer
                    ? "bg-blue-500 rounded-xl "
                    : "bg-gray-400 rounded-xl  cursor-not-allowed"
                  } transition-all mt-4 w-5/6 mx-auto text-center lg:ml-0 flex items-center justify-center px-6 lg:px-9 bg-blue-500 text-black   font-bold text-[15px] h-[49px] lg:h-[65px]  xl:text-[18px]`}
              >
                Complete Offer
              </button>
            </div>
        </div>
        

          
      </div>
    </>
  );
};

export default Summary;
