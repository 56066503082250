import { Loader, Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useLocation, useNavigate } from "react-router-dom";
import baseUrl from "../context/baseUrl";
import ProductSummary from "../componet/ProductSummary";

const Basket = ({cart, userInfo, setCart}) => {
  const location = useLocation();
  const navigation = useNavigate();
  // const data = location.state && location.state.data
  const SearchValue = location.state.SearchValue;
  const condition = location.state.condition;
  const productCondition = location.state.productCondition;
  const [isLoading, setIsLoading] = useState(false);
  // console.log("price", price)
  const route = location.pathname;
  const [opened, { open, close }] = useDisclosure(false);
  const [productDetails, setProductDetails] = useState({
    id: "",
    productId: "",
    name: "",
    img: "",
    price: 0,
    qty: 1,
    subTotal: 0,
    Status: "Pending",
    condition: productCondition
});

const updateCart = async (product) => {
  try {
    setIsLoading(true);
    const response = await fetch(`${baseUrl}/cart`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "user-id": userInfo?.userId,
        // Authorization: `Bearer ${userInfo.token}`,
      },
      body: JSON.stringify({product}),
    });
    const data = await response.json();
    if (response.status === 200) {
      console.log(data);
      setCart(data);
      
    } else {
      alert("Could not add to cart");
    }
  } catch (error) {
    console.log(error);
    alert("Could not add to cart");
  } finally {
    setIsLoading(false);
  }
};

const renewCart = (product) => {
  const items = [...cart.items, product];
  const totalQty = items.reduce((acc, item) => acc + item.qty, 0);
  const total = items.reduce((acc, item) => acc + item.qty * item.price, 0);
  return {
    ...cart,  // Copy the current cart state
    items: items,  // Merge the items arrays
    total: total,  // Add the totals
    totalQty: totalQty  // Add the total quantities
  };
}

const addToCart = async () => {
   
    if (!userInfo) {
      setCart(renewCart(productDetails));
    } else {    
      setCart(renewCart(productDetails));  
      await updateCart(productDetails);      
    }
  };
  

  function updateData(type, newData){
    setProductDetails((productDetails) =>{
      return {...productDetails, [type]: newData}
    });
  }


  const increment = () => {
    console.log("Incrementing", productDetails);
    updateData("qty", productDetails?.qty + 1);
    // updateData("subTotalPrice", productDetails.price * (productDetails.qty + 1));
  };

  const removeProduct = () => {
    localStorage.removeItem("productDetails");
    
    setProductDetails({
      id: "",
      productId: "",
      name: "",
      img: "",
      price: 0,
      qty: 0,
      subTotal: 0,
      Status: "Pending",
      condition: productCondition
    });
    navigation("/");
    // window.location.reload();
  };

  const decrement = () => {
    if (productDetails?.qty > 1) {
      updateData("qty", productDetails?.qty - 1);
      // updateData("subTotal", productDetails.price * (productDetails.qty - 1));
    }
  };

  useEffect(() => {
    updateData("subTotal", productDetails?.price * productDetails?.qty);
  }, [productDetails?.qty, productDetails?.price]);

  useEffect(() => {
    updateData("productId", SearchValue);
    if(productCondition === "Mint"){
      updateData("id", SearchValue + "M");
    }else if(productCondition === "Very Good"){
      updateData("id", SearchValue + "G");
    }
  }, [SearchValue, productCondition]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${baseUrl}/find-lego`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ itemCode: SearchValue }),
        });

        const productData = await response.json();
        updateData("name", productData.body.name);
        updateData("img", productData.body.image_url);
      } catch (error) {
        // console.log(error)
        // alert("Could not find the LEGO you are looking for.")
      } finally {
        // console.log("Complete")
        // Set loading state back to false
      }
    };

    fetchData(); // Call the fetchData function
  }, [SearchValue]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${baseUrl}/calculate-price`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ itemCode: SearchValue }),
          }
        );

        const priceData = await response.json();
        
        if (priceData.body.price52 === null) {

          open(true);
          setTimeout(() => {
            navigation("/");
          }, 5000);
        }

        const originalPrice = priceData.body.price.min_price;
        const discountPercentage = condition;
        const discount = originalPrice * (discountPercentage / 100);
        const discountedPrice = (originalPrice - discount);
        // updateData("price", discountedPrice);
        // console.log("Discounted price: " + discountedPrice);
        if (priceData.message === "SUCCESS") {
          
          updateData("price", discountedPrice.toFixed(2));
        } else {
          // alert("Could not find the LEGO you are looking for.")
        }
      } catch {
        // alert("Could not find the LEGO you are looking for.")
      }
    };

    fetchData();
  }, [SearchValue, condition, navigation, open]);



  return (
    <div className="lg:px-6 lg:flex-row flex-col px-4   space-x-0 lg:space-x-4 items-center lg:items-start flex py-4" >
      <Helmet>
        <meta charSet="utf-8" />

        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="keywords" content="Lego, sell, Lego2Sell, Lego 2 Sell" />
        <meta name="viewport" content="width=device-width" />
        <meta
          property="og:description"
          img="https://lego2sell.com/Images/Logo.png"
          content="Lego2Sell.com is the quick, convenient, and free online platform to sell your LEGO® sets for cash! Whether you have a complete collection or a mixed assortment of lego, we're here to buy. Start selling your LEGO with Lego2Sell.com today!"
        />
      </Helmet>
      <div className="border w-full flex-1 py-6 px-4   border-gray-300 rounded-xl">
        <Modal opened={opened} onClose={() => {
          close();
          navigation("/");

        }} title="Woops" centered>
          {/* Modal content */}
          <div className="">
            <img
              className="w-full"
              src="/Images/SearchErrorMessage.jpg"
              alt=""
              loading="lazy"
            />
            <p className="text-gray-400 text-base font-normal py-4">
              We are sorry but we can not seem to find a price for that set! If
              you still want to check please contact{" "}
              <Link
                className="text-blue-500 font-medium"
                to={"mailto:support@lego2sell.com"}
              >
                support@lego2sell.com
              </Link>
            </p>
          </div>
        </Modal>
        <div className="flex flex-col lg:flex-row items-stretch lg:justify-between ">
          <div className="flex flex-col w-full lg:flex-row items-center gap-6">
            <img 
              className="lg:w-44 w-[95%] p-2 object-contain  border rounded-lg border-gray-300 h-full lg:h-32"
              src={productDetails?.img} alt={productDetails?.name} 
              style={{ maxHeight: "260px" }}
            />
            <h3 className=" text-xl w-full  lg:text-lg font-semibold">
              {productDetails?.name} {SearchValue}
            </h3>
          </div>
          <div className="flex items-center w-full lg:w-1/12 justify-items-end  lg:py-0 py-4">
            {productDetails?.price ? (
              <h2 className="text-blue-500 text-sm lg:text-xl font-semibold">
                £{productDetails?.price}
              </h2>
            ) : (
              <Loader size="xs" />
            )}
            
              
            
          </div>
      </div>
  </div>
  <div className="w-full lg:w-2/5  p-2 border rounded-xl border-gray-300 flex flex-col justify-between">
    {productDetails?.price ? (
      <ProductSummary removeProduct={removeProduct} increment={increment} decrement={decrement} productDetails={productDetails} />
    ) : (
      <Loader size="xs" />
    )}
    <div className="flex flex-col md:flex-row items-center justify-center mt-4">
      <button
        onClick={()=>{ 
          addToCart();
          navigation("/your-basket",{
            state: {
              productDetails: cart?.items,
            },
        });
        }}
        type="button"
        className="hover:scale-[1.05] transition-all w-full text-center p-2 m-2 lg:ml-0 flex items-center justify-center px-2 mx-2 md:w-1/2 rounded-xl bg-blue-500 hover:bg-white hover:text-black font-bold text-xs h-[49px] lg:h-[65px] xl:text-[18px]"
      >
        Add to Basket
      </button>

      <button
        onClick={() => {
          addToCart();
          if (!userInfo) {
            navigation("/login/", {
              state: { route, productCondition, productDetails, SearchValue },
            });
          } else {
            addToCart();
           
             navigation("/check-your-details", {
              state: {
                productDetails
              },
            });
          
          }
        }}
        type="button"
        className="hover:scale-[1.05] transition-all w-full text-center lg:ml-0 flex items-center justify-center p-2 m-2 md:w-1/2 rounded-xl bg-blue-500 hover:bg-white hover:text-black font-bold text-[15px] h-[49px] lg:h-[65px] xl:text-[18px]"
      >
        Accept Offer
      </button>
    </div>
  </div>
</div>

  );
};

export default Basket;
