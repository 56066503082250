import React from "react";
import { TrashIcon } from "@heroicons/react/20/solid";

export default function Item({ product, increment, decrement, removeProduct, removeContoller }) {
    return (
        
      <div className=" text-xl md:text-base items-center justify-between pb-4 mb-3 border-b-2">
    <div className="flex justify-between mb-1 mx-2 ">
        
        <div className="flex w-3/5 items-center justify-between">
            <p className="text-sm px-2 w-3/4">{product?.name} {product?.productId}</p> 
            <p className="text-sm text-black w-1/4 px-2 justify-center">{product?.condition}</p>
        </div>
        <div className="flex w-2/5 items-center justify-end">
            <p className="text-sm text-black px-2 mx-2">Offer Price</p>
            <span className="text-black font-bold  pe-3 text-right"> £{product?.price}</span>
        </div>
    </div>
    <div className="flex w-full items-center justify-between mx-1 px-4">
        <img src={product?.img} alt={product?.name} className="h-12 w-12" />
        {removeContoller ? <></>:
        <div className="flex justify-between">
        <span className="text-sm text-gray-900 px-2">Qty</span>
        <div className="flex items-center justify-between">
            <button
                type="button"
                className="inline-flex h-6 w-6 items-center justify-center rounded-md border border-gray-300 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-0 active:outline-none active:ring-0"
                onClick={decrement}
            >
                <svg
                    className="h-2.5 w-2.5 text-gray-900"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 18 2"
                >
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h16"></path>
                </svg>
            </button>
            <input
                type="text"
                className="w-10 shrink-0 border-0 bg-transparent text-center text-sm font-medium text-gray-900"
                value={product?.qty}
                readOnly
            />
            <button
                type="button"
                className="inline-flex h-6 w-6 items-center justify-center rounded-md border border-gray-300 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-0 active:outline-none active:ring-0"
                onClick={increment}
            >
                <svg
                    className="h-2.5 w-2.5 text-gray-900"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 18 18"
                >
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 1v16M1 9h16"></path>
                </svg>
            </button>
            <button type="button focus:outline-none focus:ring-0 active:outline-none active:ring-0" onClick={removeProduct}>
                <TrashIcon className="h-6 w-6 text-red-700 mx-2" />
            </button>
            </div>
        </div>}
        <span className="text-black font-bold justify-end w-20 text-right">£{product?.subTotal?.toFixed(2)}</span>
    </div>
</div>

    );
  }