import React, { useEffect, useState } from "react";
import Adminorder from "../componet/Adminorder";
import { useNavigate } from "react-router-dom";
import baseUrl from "../context/baseUrl";

const Admin = ({userInfo}) => {
  const [data, setData] = useState();

  const fetchInfo = () => {
    return fetch(`${baseUrl}/GetUsers`)
      .then((res) => res.json())
      .then((d) => setData(d.data));
  };
  // console.log(data)
  useEffect(() => {
    fetchInfo();
  }, []);
  useEffect(() =>{
    if(!userInfo){
      navigation("/login");
    }else if(userInfo && userInfo.isAdmin === false){
      navigation("/");
    }
  })
  const [SearchValue, setSearchValue] = useState();
  const navigation = useNavigate();
  return (
    <div className="">
      <div className="lg:px-44 px-6 py-4 my-6">
        <div className="flex items-center justify-between">
          <button
            onClick={() => navigation("/AdminDashboard")}
            className="px-6 py-2 rounded-xl bg-blue-500 text-white font-medium"
          >
            Back
          </button>
          <h4 className="lg:text-2xl text-sm text-center font-semibold">
            Customer Accounts
          </h4>
          <button
            onClick={() => navigation("/customeroffers")}
            className="px-6 py-2 text-sm lg:text-base rounded-xl bg-blue-500 text-white font-medium"
          >
            Customer Offers
          </button>
        </div>
        <div className="mt-4 mb-6">
          <h3 className="text-xl lg:text-lg font-bold py-2">Search Customer using Name or Account ID or Email</h3>
          {/* <SearchDas data={data} /> */}
          <input
            placeholder="search Customer"
            className="border px-6 rounded-xl w-full py-4"
            type="text"
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
        {data?.filter((value) => {
  if (SearchValue) {
    // Check if _id matches SearchValue
    if (value?._id?.includes(SearchValue)) {
      return true;
    }
    
    // Check if firstName matches SearchValue
    if (value?.Mydetails[0]?.firstName?.toLowerCase()?.includes(SearchValue)) {
      return true;
    }
    
    // Check if email matches SearchValue
    if (value?.email?.includes(SearchValue)) {
      return true;
    }
    
    // If none of the above conditions match, return false
    return false;
  }
  
  // If SearchValue is empty, include all items
  return true;
})
          .reverse()
          .map((value) => (
            
            <Adminorder
            
              key={value._id}
              user={value}
              userInfo={userInfo}
            />
            
          ))}
      </div>
    </div>
  );
};

export default Admin;
