import React, { useEffect, useState } from 'react';
import baseUrl from '../context/baseUrl';
import { Helmet } from 'react-helmet';

const AddDiscountForm = () => {
  const [allDiscounts, setAllDiscounts] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    code: '',
    amount: 0,
    // amountType: '%',
    startDate: '',
    startTime: '',
    expirationDate: '',
    expirationTime: '',
    // minAmount: 0,
    // maxUses: '',
    // useOnce: false,
    // status: 'Active',
    // notes: '',
  });

  const formatUKDateTime = (isoString) => {
    const date = new Date(isoString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
  
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    // Combine date and time for start and expiration
    const startDateTime = new Date(`${formData.startDate}T${formData.startTime}`);
    const expirationDateTime = new Date(`${formData.expirationDate}T${formData.expirationTime}`);

    if (startDateTime > expirationDateTime) {
      alert("Start date and time must be before expiration date and time.");
      return;
    }
    if (!editMode) {
      try{
        const response = await fetch(`${baseUrl}/discount`, {
            method: "POST",
            headers: {
            "Content-Type": "application/json",
            },
            body: JSON.stringify({ formData }),
        });

        const data = await response.json();
        data && alert("Discount added successfully");
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      try {
        const response = await fetch(`${baseUrl}/discount/${formData._id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ formData }),
        });
        const data = await response.json();
        data && alert("Discount updated successfully");
      } catch (error) {
        console.error("Error:", error);
      }
    }
    fetchDiscounts(); // Refresh the discounts list   
  };

  const editDiscount = (discount) => {
    setEditMode(true);
    // Extract the ISO string
    const startDateTime = discount.startDate;
    const expirationDateTime = discount.expirationDate;
  
    // Format the date and time properly
    const formattedStartDate = startDateTime.split('T')[0]; // Extract the date part (YYYY-MM-DD)
    const formattedStartTime = startDateTime.split('T')[1].slice(0, 5); // Extract the time part (HH:MM)
    const formattedExpirationDate = expirationDateTime.split('T')[0];
    const formattedExpirationTime = expirationDateTime.split('T')[1].slice(0, 5);

  setFormData({
    ...discount,
    startDate: formattedStartDate,
    startTime: formattedStartTime,
    expirationDate: formattedExpirationDate,
    expirationTime: formattedExpirationTime,
  });
  };

  const deleteDiscount = async (discount) => {
    try {
      const response = await fetch(`${baseUrl}/discount/${discount._id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ discount }),
      });
      const data = await response.json();
      
      data && alert("Discount deleted successfully");
      fetchDiscounts(); // Refresh the discounts list
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchDiscounts = async () => {
    try {
      const response = await fetch(`${baseUrl}/discount/all`);
      const data = await response.json();
      setAllDiscounts(data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    fetchDiscounts();
  }, []);

  return (
    <div >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Voucher Form| LEGO®</title>

        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="keywords" content="Lego, sell, Lego2Sell, Lego 2 Sell" />
        <meta name="viewport" content="width=device-width" />
        <meta
          property="og:description"
          content="Lego2Sell.com is the quick, convenient, and free online platform to sell your LEGO® sets for cash! Whether you have a complete collection or a mixed assortment of lego, we're here to buy. Start selling your LEGO with Lego2Sell.com today!"
        />
      </Helmet>
      <div className='flex flex-col w-full lg:w-1/2  bg-gray-100 mx-auto'>
    <form onSubmit={handleSubmit} className=" flex flex-col w-full p-2 mt-2 lg:mt-4 mx-auto lg:px-4  bg-gray-100 rounded-lg shadow-md">
      <h2 className="text-xl font-bold mb-4 text-center">Add Voucher Detail</h2>
      
      <div className="flex flex-col w-full">
        <label className="block text-left pl-2">
          Name
        </label>
          <input 
            type="text" 
            name="name" 
            value={formData.name} 
            onChange={handleChange} 
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            required 
            withAsterisk
          />
          {/* <p className="text-gray-500 text-sm mt-1">The name of the discount. User will see this at checkout.</p> */}
        
      </div>

      <div className="flex flex-col w-full">
        <label className="block text-left pl-2 mt-3">
          Code
        </label>
        
          <input 
            type="text" 
            name="code" 
            value={formData.code} 
            onChange={handleChange} 
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            required 
            withAsterisk
          />
          {/* <p className="text-gray-500 text-sm mt-1">The code customers will enter to apply this discount.</p> */}
        
      </div>

      
      <div className='flex flex-col w-full'>
        <label className="block text-left  pl-2 mt-3">
         Voucher Amount in % 
        </label>
        <input 
              type="number" 
              name="amount" 
              value={formData.amount} 
              onChange={handleChange} 
              className="mt-1 block w-full p-2 border border-gray-300 rounded-l-md lg:mr-4"
              required 
              withAsterisk
            />
        
          {/* <div className="flex flex-row w-full">
            <input 
              type="number" 
              name="amount" 
              value={formData.amount} 
              onChange={handleChange} 
              className="mt-1 block w-3/5 p-2 border border-gray-300 rounded-l-md lg:mr-4"
              required 
              withAsterisk
            />
            <select 
              name="amountType" 
              value={formData.amountType} 
              onChange={handleChange} 
              className="mt-1 p-2 border w-2/5 border-gray-300 rounded-r-md lg:ml-4"
            >
              <option value="%">%</option>
              <option value="flat">$</option>
            </select>
          </div> */}
          {/* <p className="text-gray-500 text-sm mt-1">The amount as a percentage or flat rate. Cannot be left blank.</p> */}
      </div>

      <div className='flex flex-col w-full'>
      
        <label className="block text-left pl-2 mt-3 ">
          Start Date &amp; Time
        </label>
        <div className='flex flex-row w-full'>
          <input 
            type="date" 
            name="startDate" 
            value={formData.startDate} 
            onChange={handleChange} 
            className="mt-1 block w-1/2 p-2 border border-gray-300 rounded-md mr-4"
          />
          <input 
            type="time" 
            name="startTime" 
            value={formData.startTime} 
            onChange={handleChange} 
            className="mt-1 block w-1/2 p-2 border border-gray-300 rounded-md ml-4"
          />
          {/* <p className="text-gray-500 text-sm mt-1">Put the date and time. This discount will start on this date. Leave blank for no start date.</p> */}
        
      </div>

      </div>
      <div className='flex flex-col w-full'>
        <label className="block text-left pl-2 mt-3">
          Expiration Date &amp; Time
        </label>
        <div className='flex flex-row w-full'> 
          <input 
            type="date" 
            name="expirationDate" 
            value={formData.expirationDate} 
            onChange={handleChange} 
            className="mt-1 block w-1/2 p-2 border border-gray-300 rounded-md mr-4"
          />
          <input 
            type="time" 
            name="expirationTime" 
            value={formData.expirationTime} 
            onChange={handleChange} 
            className="mt-1 block w-1/2 p-2 border border-gray-300 rounded-md ml-4"
          />
          {/* <p className="text-gray-500 text-sm mt-1">Put the date and time. This discount will expire on this date. Leave blank to never expire.</p> */}
        </div>
      </div>

      
      {/* <div className='flex flex-col w-full'>
        <label className="block text-left pl-2 mt-3">
          Min Amount
        </label>
        
          <input 
            type="number" 
            name="minAmount" 
            value={formData.minAmount} 
            onChange={handleChange} 
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
          />
           <p className="text-gray-500 text-sm mt-1">The minimum subtotal of item price in a cart before this discount may be applied.</p> 
      </div> */}

      
      {/* <div className='flex flex-col w-full'>
        <label className="block text-left pl-2 mt-3">
          Max Uses
        </label>
        
          <input 
            type="number" 
            name="maxUses" 
            value={formData.maxUses} 
            onChange={handleChange} 
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
          />
          <p className="text-gray-500 text-sm mt-1">The max number of times this discount can be used.</p>
        
      </div> */}

      
      {/* <div className='flex flex-row w-full'>
        <label className="block text-left pl-2 mt-3">
        Use Once
        </label>
        
          <input 
            type="checkbox" 
            name="useOnce" 
            checked={formData.useOnce} 
            onChange={handleChange} 
            className="mt-1 block mx-4 p-2 border border-gray-300 rounded-md "
          />
          
          <p className="text-gray-500 text-sm mt-1">Prevent customers from using this discount more than once.</p>
        
      </div> */}

     
      {/* <div className='flex flex-col w-full'>
        <label className="block text-left pl-2 mt-3">
          Status
        </label>
        
          <select 
            name="status" 
            value={formData.status} 
            onChange={handleChange} 
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            required
            withAsterisk
          >
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
          </select>
          <p className="text-gray-500 text-sm mt-1">The status of this discount code.</p>
      </div> */}

      
      {/* <div className='flex flex-col w-full'>
        <label className="block text-left pl-2 mt-3">
          Discount Notes
        </label>
        
          <textarea 
            name="notes" 
            value={formData.notes} 
            onChange={handleChange} 
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
          />
          <p className="text-gray-500 text-sm mt-1">Any additional information about the discount.</p>
        
      </div> */}

      <div className="flex flex-col md:flex-row gap-4 my-12">
      {/*    Empty div to align the button to the right */}
        <button 
          type="submit" 
          className="w-full lg:w-1/2 lg:m-auto py-2  bg-blue-500 text-white font-bold rounded-lg hover:bg-blue-600"
        >
          {editMode ? 'Update Discount' : 'Add Discount'}
        </button>
      </div>
    </form>
    <div className='flex flex-col w-full p-2 mt-2 lg:mt-4 mx-auto lg:px-4 border-2 border-black bg-gray-100 rounded-lg shadow overflow-x-auto justify-between'>
            <table >
              <thead>
                <tr>
                  <th className='py-2 px-4 border-b-2 border-gray-200 text-left font-semibold'> Name </th>
                  <th className='py-2 px-4 border-b-2 border-gray-200 text-left font-semibold'> Created Date </th>
                  <th className='py-2 px-4 border-b-2 border-gray-200 text-left font-semibold'> Action </th>
                </tr>
                {allDiscounts.map((discount) => (
                  <tr key={discount._id}>
                    <td className='py-2 px-4 border-b-2 border-gray-200 text-left'>{discount.name}</td>
                    <td className='py-2 px-4 border-b-2 border-gray-200 text-left'>{formatUKDateTime(discount.createdAt)}</td>
                    <td className='py-2 px-4 border-b-2 border-gray-200 text-left'>
                      <button className='border-2 border-black rounded-md m-2 p-2' 
                      onClick={() => editDiscount(discount)}
                      > Edit </button>
                      <button className='border-2 border-red-700 rounded-md m-2 p-2'
                      onClick={() => deleteDiscount(discount)}
                      > Delete </button>
                    </td>
                  </tr>
                ))}
                
              </thead>
            </table>
        </div>

</div>
        
  </div>
  );
};

export default AddDiscountForm;
