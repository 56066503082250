import { useDisclosure } from "@mantine/hooks";
import React from "react";
import { degrees, PDFDocument, rgb, StandardFonts } from "pdf-lib";
import download from "downloadjs";
import OfferDetails from "./OfferDetails";
      
const OrderCards = ({ 
  timestamp,
  order,
  getMyDetails,
  validStatuses
}) => {
  
  const [opened, { open, close }] = useDisclosure(false);
  const handleModifyPdf = async () => {
    const url = "/completpdf.pdf";

    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

    const pages = pdfDoc.getPages();
    const firstPage = pages[1];
    // const { width, height } = firstPage.getSize();
    console.log(order);
    firstPage.drawText(`${timestamp}`, {
      x: 220,
      y: 240,
      size: 18,
      font: helveticaFont,
      color: rgb(0, 0, 0),
      rotate: degrees(0),
    });
    // const second = pages[1];
    // const { width1, height1 } = second.getSize();
    firstPage.drawText(`#${order.offerId}`, {
      x: 220,
      y: 200,
      size: 18,
      font: helveticaFont,
      color: rgb(0, 0, 0),
      rotate: degrees(0),
    });

    const pdfBytes = await pdfDoc.save();
    // Trigger the browser to download the PDF document
    download(pdfBytes, "lego2sellPDF.pdf", "application/pdf");
  };

  return (
    <div className="py-3">
      <div
        onClick={open}
        className="last:mb-0 bg-white shadow-[0_4px_20px_rgba(0,0,0,0.15)] rounded-2xl p-6 px-4 lg:px-8 cursor-pointer"
      >
        <div className="flex items-center justify-between">
          <div className="mr-auto font-medium">
            Offer ID: #{order.offerId}
            <br className="md:hidden" />
            
            <span className="hidden text-blue-500 font-bold">£{order?.totalPrice?.toFixed(2)}</span>
            <div className="text-sm lg:hidden flex font-medium text-black pr-4">
              
            </div>
          </div>
          <div className="text-sm lg:block hidden font-medium text-black pr-4">
            {order?.timestamp}
          </div>
          {/* <div className="rounded-full text-xs px-6 lg:mt-0 py-2 font-bold bg-blue-500 text-white mr-5 lg:mr-7">
            {Status}
          </div> */}
          <div className="text-blue-500 font-bold mr-6 hidden md:flex">
            Watch Details
            {/* £{order.totalPrice?.toFixed(2)} */}
          </div>
          <div className="text-blue-500 text-lg">
            <svg
              width={8}
              aria-hidden="true"
              focusable="false"
              data-prefix="far"
              data-icon="angle-right"
              className="svg-inline--fa fa-angle-right fa-w-6 "
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 192 512"
            >
              <path
                fill="currentColor"
                d="M187.8 264.5L41 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 392.7c-4.7-4.7-4.7-12.3 0-17L122.7 256 4.2 136.3c-4.7-4.7-4.7-12.3 0-17L24 99.5c4.7-4.7 12.3-4.7 17 0l146.8 148c4.7 4.7 4.7 12.3 0 17z"
              />
            </svg>
          </div>
        </div>
      </div>
      <OfferDetails 
        order={order} 
        handleModifyPdf={handleModifyPdf} 
        opened={opened} 
        close={close} 
        getMyDetails={getMyDetails}  
        validStatuses={validStatuses}  />
    </div>
  );
};

export default OrderCards;
