import React from "react";
import { Modal, Select } from "@mantine/core";

const OfferDetails = ({
  order,
  getMyDetails,
  opened, 
  close, 
  validStatuses, 
  handleUpdate,
  userId,
  isAdmin}) => {
    // const handleChanges = (e, value) => {
    //   handleUpdate(value.id, e.target.value);
    // }
    const total = order?.orderItems?.filter((item) => { 
      return validStatuses.includes(item?.Status);
    })?.map((item) => item?.subTotal).reduce((a, b) => a + b, 0);
    const totalQty = order?.orderItems?.filter((item) => {
      return validStatuses.includes(item?.Status);
    })?.map((item) => item?.qty).reduce((a, b) => a + b, 0);
    const bonus = order?.discount >0 ? total * order?.discount/100 : 0;
    const finalTotal = total + bonus;
    
    return (
        <Modal
        title= {isAdmin ? "What you will be sending to us." : "Offer Details"}
        centered
        opened={opened}
        onClose={close}
        withCloseButton={true}
        size={"xxl"}
      >
        <div className="absolute top-0 right-0 p-0 lg:p-6 text-xl cursor-pointer">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="far"
            data-icon="times"
            className="svg-inline--fa fa-times fa-w-10 "
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path
              fill="currentColor"
              d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"
            />
          </svg>
        </div>
        <div>
            <div className="flex flex-row items-center justify-center p-2 text-blue-500 border-b-lime-500 ">
                <h1>Offer ID: #{order?.offerId}</h1> 
                <hr className="mt-4" />
            </div>
            <div className="flex flex-row items-center justify-between p-2 text-blue-500 border-b-lime-500 ">
                
                <div className="flex flex-col ">
                    <h2>Date &amp; Time: <span>{order?.timestamp}</span> </h2>
                    {/* <h2> Delivery methods: <span>{order?.Deliverymethod}</span> </h2> */}
                </div>
                <div className="flex flex-col ">
                    
                    <h2>Payment Method: {getMyDetails?.paymentMethod}</h2>
                    <h2> No. of items: {totalQty}</h2>
                </div>
            </div>
            
            <div className="overflow-x-auto">
                <table className="min-w-full">
                    <thead> 
                        <tr> 
                            <th className="py-2 px-4 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">Product Name</th>
                            <th className="py-2 px-4 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">Product ID</th>
                            <th className="py-2 px-4 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">Condition</th>
                            <th className="py-2 px-4 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700 w-32">Status</th>
                            <th className="py-2 px-4 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">Quantity</th>
                            <th className="py-2 px-4 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">Offer Price</th>
                            <th className="py-2 px-4 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">Sub Total Price</th>
                        </tr>
                    </thead>
                    <tbody> 
                    
                        
                        {order?.orderItems?.filter((item) => { 
                    return validStatuses.includes(item?.Status);
                  })?.map((item, index) => (
                            
                                <tr key={index}>
                                    <td className="py-2 px-4 border-b border-gray-200">{item?.name}</td>
                                    <td className="py-2 px-4 border-b border-gray-200">{item?.productId}</td>
                                    <td className="py-2 px-4 border-b border-gray-200">{item?.condition}</td>
                                    <td className="py-2 px-2 border-b border-gray-200 w-32">
                                      {isAdmin ? <Select 
                                      onChange={(value) => handleUpdate( value, order._id, item.id, userId )}
                                      value={item?.Status}
                                      data={[
                                        { value: "Pending", label: "Pending" },
                                        { value: "Received", label: "Received" },
                                        { value: "Checking", label: "Checking" },
                                        { value: "Accepted", label: "Accepted" },
                                        { value: "Rejected", label: "Rejected" },
                                        { value: "Paid", label: "Paid" },
                                      ]} /> :
                                      <p>{item?.Status}</p>}
                                      </td>
                                    <td className="py-2 px-4 border-b border-gray-200">{item?.qty}</td>
                                    <td className="py-2 px-4 border-b border-gray-200">£{item?.price?.toFixed(2)}</td>
                                    <td className="py-2 px-4 border-b border-gray-200">£{item?.subTotal?.toFixed(2)}</td>
                                </tr>
                            ))}
                        <tr >
                                    <td className="py-2 px-4 border-b border-gray-200"></td>
                                    <td className="py-2 px-4 border-b border-gray-200"></td>
                                    <td className="py-2 px-4 border-b border-gray-200"></td>
                                    <td className="py-2 px-2 border-b border-gray-200 ">                                </td>
                                    <td className="py-2 px-4 border-b border-gray-200"></td>
                                    <td className="py-2 px-4 border-b border-gray-200">Total Price</td>
                                    <td className="py-2 px-4 border-b border-gray-200">£{order?.totalPrice?.toFixed(2)}</td>
                                </tr>
                                { order?.discount >0 ? <tr >
                                    <td className="py-2 px-4 border-b border-gray-200"></td>
                                    <td className="py-2 px-4 border-b border-gray-200"></td>
                                    <td className="py-2 px-4 border-b border-gray-200"></td>
                                    <td className="py-2 px-2 border-b border-gray-200 ">                                </td>
                                    <td className="py-2 px-4 border-b border-gray-200"></td>
                                    <td className="py-2 px-4 border-b border-gray-200">Voucher {order.discount}%</td>
                                    <td className="py-2 px-4 border-b border-gray-200">£{bonus.toFixed(2)}</td>
                                </tr>: null}
                                <tr >
                                    <td className="py-2 px-4 border-b border-gray-200"></td>
                                    <td className="py-2 px-4 border-b border-gray-200"></td>
                                    <td className="py-2 px-4 border-b border-gray-200"></td>
                                    <td className="py-2 px-2 border-b border-gray-200 ">                                </td>
                                    <td className="py-2 px-4 border-b border-gray-200"></td>
                                    <td className="py-2 px-4 border-b border-gray-200">Final Price</td>
                                    <td className="py-2 px-4 border-b border-gray-200">£{finalTotal.toFixed(2)}</td>
                                </tr>
                    </tbody>
                </table>
            </div>
            <div className="flex  flex-col w-full lg:w-1/2 items-end mt-2 pr-20 mx-auto">
      
      </div>
          
        </div>
      </Modal>
      )};

export default OfferDetails;