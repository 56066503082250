import React from "react"
import { Link } from "react-router-dom"
import { Helmet } from 'react-helmet'

const Packaging = () => {
  return (
    <div>
      <Helmet>
        <title>LEGO® Packaging Guidelines: Ensuring Optimal Quality and Brand Consistency</title>
        <meta
          name="description"
          content="Discover the LEGO® Packaging Guidelines, your ultimate resource for ensuring optimal quality and brand consistency. Learn how to create packaging that captures attention, protects products, and reflects the LEGO® brand values."
        />
        <meta name="keywords" content="lego packaging,lego plastic bag sets,lego packaging boxes,lego sustainable packagingLEGO® Sell, sell lego sets,custom lego sets for sale,best place to sell legos,how to sell legos,sell lego"/>


      </Helmet>
      <div className="w-full px-6 lg:px-44 py-12 lg:py-24">
        <h1 className="text-5xl font-bold h1 mb-8">Packaging guidelines</h1>
        <div className="content-wrapper">
          <div>
            <div className="text-base lg:text-lg text-black font-medium">
              <p>
                <img
                  className="rounded-2xl h-[205px] lg:h-[446px] object-cover lg:object-cover w-full"
                  src="/Images/Packaging.png"
                  alt="Packaging Guidelines | Sell Lego Online"
                />
              </p>
              <p className="py-4 mt-6">
                Our packaging guidelines will help you protect your LEGO® during
                transportation, so your collection reaches us in the same
                condition you sent it. Take a look at our packaging dos and
                don'ts below…
              </p>
              <div className="green-box text-sm lg:text-lg  py-8 bg-green-100 my-12 rounded-3xl px-12">
                <h2 className="text-black py-4 font-bold">
                  Packaging <span className="text-green-500">dos</span>
                </h2>
                <div className="flex py-2">
                  ✅
                  <div className="ml-4">
                    <h5 className="mb-0">Double-check your LEGO®</h5>
                    <p>
                      Before you send us your sets check them over and make sure
                      they meet our condition guidelines. It might be worth
                      taking a photo of your <a href="https://www.lego2sell.com/">LEGO<span
                          
                          style={{
                            fontSize: ".6rem",
                            position: 'absolute',
                            // marginLeft: -1
                          
                          }}

                          >
                            ®
                            </span>&nbsp; being packed</a>.
                    </p>
                  </div>
                </div>
                <div className="flex py-2">
                  ✅
                  <div className="ml-4">
                    <h5 className="mb-0">Choose the right box size</h5>
                    <p>
                      Choosing the right box size will prevent your LEGO® from
                      being damaged in transit. A box too small for your LEGO®
                      could burst, whereas a box too big could collapse.
                    </p>
                  </div>
                </div>
                <div className="flex py-2">
                  ✅
                  <div className="ml-4">
                    <h5 className="mb-0">Secure your package</h5>
                    <p>
                      Whatever you decide to send your LEGO® in, make sure it’s
                      sturdy and durable. Because LEGO® boxes are fragile, it’s
                      easy for them to get lost in transit as well if your
                      package isn’t secure.
                    </p>
                  </div>
                </div>
                <div className="flex py-2">
                  ✅
                  <div className="ml-4">
                    <h5 className="mb-0">Remove any existing labels</h5>
                    <p>
                      Make sure your box is clear of old shipping labels or
                      stickers, as this can cause confusion at the parcel shop.
                      You don’t want your items to end up getting delivered back
                      to you!
                    </p>
                  </div>
                </div>
                <div className="flex py-2">
                  ✅
                  <div className="ml-4">
                    <h5 className="mb-0">Attach postage label</h5>
                    <p>
                      Ensure your postage label is stuck on properly and not
                      obscured by any tape. Also, add your details inside the
                      box so we know they're yours (packing slip on checkout).
                    </p>
                  </div>
                </div>
                <div className="flex py-2">
                  ✅
                  <div className="ml-4">
                    <h5 className="mb-0">Post within 7 days</h5>
                    <p>
                      Please post your LEGO® to us within 7 days of completing
                      your sale.
                    </p>
                  </div>
                </div>
              </div>
              <div className="red-box py-8 text-sm lg:text-lg px-12 mb-8 rounded-3xl bg-red-100">
                <h3 className="text-black py-4 font-bold">
                  Packaging <span className="text-red-500">don'ts</span>
                </h3>
                <div className="flex py-2">
                  ⛔️
                  <div className="ml-4">
                    <h5 className="mb-0">Don’t pack badly</h5>
                    <p>
                      Don't ship your LEGO® in flimsy or damaged boxes as it
                      will likely sustain additional damage during transit.
                      Ensure the box is in good shape and not worn out. It must
                      be sealed properly, making sure any gaps are shut tight.
                    </p>
                  </div>
                </div>
                <div className="flex py-2">
                  ⛔️
                  <div className="ml-4">
                    <h5 className="mb-0">
                      Label as fragile instead of careful packing
                    </h5>
                    <p>
                      Labelling your boxes as ‘fragile’ or ‘handle with care'
                      doesn’t guarantee that they won’t get damaged. These
                      stickers should not be used as a substitute for <a href="https://www.lego.com/en-us/sustainability/environment/sustainable-packaging">good packaging</a>.
                    </p>
                  </div>
                </div>
                <div className="flex py-2">
                  ⛔️
                  <div className="ml-4">
                    <h5 className="mb-0">Don’t allow contents to move</h5>
                    <p>
                      Choose the appropriate-sized box for your items. Try not
                      to under-fill boxes (these could collapse) and over-filled
                      ones might burst open.
                    </p>
                  </div>
                </div>
                <div className="flex py-2">
                  ⛔️
                  <div className="ml-4">
                    <h5 className="mb-0">
                      Resend packaged without removing old labels
                    </h5>
                    <p>
                      Head over to our FAQs page to see if we’ve answered any of
                      your questions there. Alternatively, get in touch with us
                      by emailing support@lego2sell.com
                    </p>
                  </div>
                </div>
              </div>

              <h3 className="text-black py-4 font-bold" id="stillneedanswers">
                Still need answers?
              </h3>
              <p className="py-4">
                Head over to our FAQs page to see if we’ve answered any of your
                questions there. Alternatively, get in touch with us by
                emailing 
                <Link
                  className="text-blue-500 pl-2"
                  to="mailto:support@lego2sell.com"
                >
                  support@lego2sell.com{" "}
                </Link>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Packaging
