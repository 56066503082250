import React, { useEffect} from "react";
import { Helmet } from "react-helmet";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Item from "../componet/Item";
import { Loader } from "@mantine/core";

const Cart = ({cart, isLoading, userInfo, deleteCart, updateCart}) => { 
  const location = useLocation();
  const navigate = useNavigate();
  const route = location.pathname;

  const increment = async (item) => {
    item.qty += 1;
    await updateCart(item);
  };

  const decrement = async (item) => {
    if (item.qty > 1) {
      item.qty -= 1;
     await updateCart(item);
    }
  };

  const removeProduct = async (id) => {
   await deleteCart(id);
  };

  
  // useEffect(() => {
  //   if(isLoading) {
  //     console.log("Loading");
  //     return <Loader />;
  //   }else if(!isLoading && !cart){
  //     console.log("No cart");
  //     console.log(cart);
  //     navigate("/");
  //   }else{
  //     console.log("Cart found");
  //     cart?.items?.length === 0 && navigate("/");
      
  //   }},[cart, isLoading, navigate]); 

 

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="keywords" content="Lego, sell, Lego2Sell, Lego 2 Sell" />
        <meta
          property="og:description"
          img="https://lego2sell.com/Images/Logo.png"
          content="Lego2Sell.com is the quick, convenient, and free online platform to sell your LEGO® sets for cash! Whether you have a complete collection or a mixed assortment of lego, we're here to buy. Start selling your LEGO with Lego2Sell.com today!"
        />
      </Helmet>

      <div className="border w-full flex-1 py-6 px-4 lg:px-12 border-gray-300 rounded-xl">
        {isLoading ? 
          <Loader />
         : !isLoading && cart?.items?.length === 0 ? <Navigate to="/" />: <div>
          <h2 className="text-xl font-semibold">Your Offer Summary</h2>
        

         <div className="flex flex-col w-full lg:w-2/5 m-auto">
          <div className="flex mb-4 pb-4 border-b-2 items-start">
            <p>Basket</p>
          </div>

          {cart?.items?.map((item, index) => (
            <Item
              key={index}
              index={index}
              product={item}
              increment={() => increment(item)}
              decrement={() => decrement(item)}
              removeProduct={() => removeProduct(item.id)}
            />
          ))}

          <div className="flex justify-between mx-3">
            <p>Total</p>
            <span className="text-black text-xl font-bold">£{cart?.total?.toFixed(2)}</span>
          </div>
          

          <div className="flex flex-col md:flex-row items-center justify-center">
          <button
              onClick={() => {
                  navigate("/");
              }}
              type="button"
              className="hover:scale-[1.05] transition-all mt-4 w-1/2 text-center lg:ml-0 flex items-center justify-center px-2 mx-2 rounded-xl bg-blue-500 hover:bg-white hover:text-black font-bold text-[15px] h-[49px] lg:h-[65px] xl:text-[18px]"
            >
              Add Another Item
            </button>
            <button
              onClick={() => {
                if (!userInfo) {
                  navigate("/login/", {
                    state: { route },
                  });
                } else {
                  navigate("/check-your-details", {
                    state: {
                      productDetails: cart?.items,
                    },
                  });
                }
              }}
              type="button"
              className="hover:scale-[1.05] transition-all mt-4 w-1/2 text-center lg:ml-0 flex items-center justify-center px-2 mx-2 rounded-xl bg-blue-500 hover:bg-white hover:text-black font-bold text-[15px] h-[49px] lg:h-[65px] xl:text-[18px]"
            >
              Accept Offer
            </button>
          </div>
        </div>
        </div>}
      </div>
    </div>
  );
};

export default Cart;


