import React, { useEffect, useState } from "react"
import { Stepper } from "@mantine/core"
import DetailsForm from "../componet/DetailsForm"
import Summary from "../componet/Summary"
import { useLocation, useNavigate,  } from "react-router-dom"
const Details = ({cart, userInfo, updateCart, setCart, isLoading}) => {
  const [formData, setFormData] = useState([])
  const [active, setActive] = useState(0)
  const data = localStorage.getItem("data")
  // console.log(data)
  const location = useLocation();
  const navigate = useNavigate();
  const productDetails = location.state?.productDetails
    const SearchValue = location.state?.SearchValue
  const condition = location.state?.condition
  const productCondition= location.state?.productCondition
  
  // console.log("condi", productCondition)

  // function updateData(type, newData){
  //   setProductDetails((productDetails) =>{
  //     return {...productDetails, [type]: newData}
  //   });
  // }

  // const increment = () => {
  //   updateData("qty", productDetails.qty + 1);
  // };

  // const decrement = () => {
  //   if (productDetails.qty > 1) {
  //     updateData("qty", productDetails.qty - 1);
  //   }
  // };

  useEffect(() => {
    window.addEventListener("load", () => {
      if (cart?.items?.length === 0) {
        navigate("/");
      }
    });
  }, [cart, navigate]);
  

  

  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current))

 

  return (
    <div >
        <Stepper
          active={active}
          mx={{ display: "flex" }}
          onStepClick={setActive}
          breakpoint="sm"
        >
          <Stepper.Step disabled label="" description="">
            <DetailsForm
              cart={cart}
              userInfo={userInfo}
              formData={formData}
              setFormData={setFormData}
              condition={condition}
              productDetails={productDetails}
              isLoading={isLoading}
              // updateData={updateData}
              // increment={increment}
              // decrement={decrement}
              data={data}
              setActive={setActive}
            />
          </Stepper.Step>
          <Stepper.Step disabled label="" description="">
            <Summary
              isLoading={isLoading}
              cart={cart}
              setCart = {setCart}
              userInfo={userInfo}
              updateCart={updateCart}
              productCondition={productCondition}
              formData={formData}
              prevStep={prevStep}
              condition={condition}
              SearchValue={SearchValue}
              productDetails={productDetails}
              // updateData={updateData}
              data={data}
            />
          </Stepper.Step>
          <Stepper.Step disabled label="" description="">
            Step 3 content: Get full access
          </Stepper.Step>
          <Stepper.Completed>
            Completed, click back button to get to previous step
          </Stepper.Completed>
        </Stepper>
    </div>
  )
}

export default Details
