import React from "react";
import { Modal } from "@mantine/core";
import download from "downloadjs";
import { degrees, PDFDocument, rgb, StandardFonts } from "pdf-lib";

const SuccessDetails = ({
  getMyDetails,
  order,
  opened, 
  close, 
  date,
  validStatuses,
  isAdmin}) => {
    // const handleChanges = (e, value) => {
    //   handleUpdate(value.id, e.target.value);

  const extra = order?.totalPrice*(order?.discount/100);
  const final = order?.totalPrice*(1+order?.discount/100);
    const handleModifyPdf = async () => {
      const url = "/completpdf.pdf"
  
      const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer())
  
      const pdfDoc = await PDFDocument.load(existingPdfBytes)
      const helveticaFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold)
  
      const pages = pdfDoc.getPages()
      const firstPage = pages[1]
      const { width, height } = firstPage.getSize()
      firstPage.drawText(`${date}`, {
        x: 220,
        y: 240,
        size: 18,
        font: helveticaFont,
        color: rgb(0, 0, 0),
        rotate: degrees(0),
      })
      const second = pages[1]
      console.log("offerId", order)
      const { width1, height1 } = second.getSize()
      firstPage.drawText(`#${order.offerId}`, {
        x: 220,
        y: 200,
        size: 18,
        font: helveticaFont,
        color: rgb(0, 0, 0),
        rotate: degrees(0),
      })
  
      const pdfBytes = await pdfDoc.save()
      // Trigger the browser to download the PDF document
      download(pdfBytes, "lego2sellPDF.pdf", "application/pdf")
      close()
    }
    // }

    return (
        <Modal
        title= {isAdmin ? "What you will be sending to us." : "Offer Details"}
        centered
        opened={opened}
        onClose={close}
        withCloseButton={true}
        size={"xxl"}
      >
        <div className="absolute top-0 right-0 p-0 lg:p-6 text-xl cursor-pointer">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="far"
            data-icon="times"
            className="svg-inline--fa fa-times fa-w-10 "
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path
              fill="currentColor"
              d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"
            />
          </svg>
        </div>
        <div>
            <div className="flex flex-row items-center justify-center p-2 text-blue-500 border-b-lime-500 ">
                <h1>Offer ID: #{order?.offerId}</h1> 
                <hr className="mt-4" />
            </div>
            <div className="flex flex-row items-center justify-between p-2 text-blue-500 border-b-lime-500 ">
                
                <div className="flex flex-col ">
                    <h2>Date &amp; Time: <span>{order?.timestamp}</span> </h2>
                    {/* <h2> Delivery methods: <span>{order?.Deliverymethod}</span> </h2> */}
                </div>
                <div className="flex flex-col ">
                    
                    <h2>Payment Method: {getMyDetails?.paymentMethod}</h2>
                    <h2> No. of items: {order?.totalQty}</h2>
                </div>
            </div>
            
            <div className="overflow-x-auto">
                <table className="min-w-full">
                    <thead> 
                        <tr> 
                            <th className="py-2 px-4 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">Product Name</th>
                            <th className="py-2 px-4 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">Product ID</th>
                            <th className="py-2 px-4 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">Condition</th>
                            <th className="py-2 px-4 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700 w-32">Status</th>
                            <th className="py-2 px-4 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">Quantity</th>
                            <th className="py-2 px-4 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">Offer Price</th>
                            <th className="py-2 px-4 border-b-2 border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">Sub Total Price</th>
                        </tr>
                    </thead>
                    <tbody> 
                    
                        {order?.orderItems?.filter((item) => { 
                    return validStatuses.includes(item?.Status);
                  })?.map((item, index) => (
                            
                                <tr key={index}>
                                    <td className="py-2 px-4 border-b border-gray-200">{item?.name}</td>
                                    <td className="py-2 px-4 border-b border-gray-200">{item?.productId}</td>
                                    <td className="py-2 px-4 border-b border-gray-200">{item?.condition}</td>
                                    <td className="py-2 px-2 border-b border-gray-200 w-32"> <p>{item?.Status}</p>  </td>
                                    <td className="py-2 px-4 border-b border-gray-200">{item?.qty}</td>
                                    <td className="py-2 px-4 border-b border-gray-200">£{item?.price?.toFixed(2)}</td>
                                    <td className="py-2 px-4 border-b border-gray-200">£{item?.subTotal?.toFixed(2)}</td>
                                </tr>
                            ))}
                        <tr >
                                    <td className="py-2 px-4 border-b border-gray-200"></td>
                                    <td className="py-2 px-4 border-b border-gray-200"></td>
                                    <td className="py-2 px-4 border-b border-gray-200"></td>
                                    <td className="py-2 px-2 border-b border-gray-200 w-32">                                </td>
                                    <td className="py-2 px-4 border-b border-gray-200"></td>
                                    <td className="py-2 px-4 border-b border-gray-200">Total Price</td>
                                    <td className="py-2 px-4 border-b border-gray-200">£{order?.totalPrice?.toFixed(2)}</td>
                                </tr>
                                {order?.discount >0 ? <tr >
                                    <td className="py-2 px-4 border-b border-gray-200"></td>
                                    <td className="py-2 px-4 border-b border-gray-200"></td>
                                    <td className="py-2 px-4 border-b border-gray-200"></td>
                                    <td className="py-2 px-2 border-b border-gray-200 w-32">                                </td>
                                    <td className="py-2 px-4 border-b border-gray-200"></td>
                                    <td className="py-2 px-4 border-b border-gray-200">Voucher {order.discount}%</td>
                                    <td className="py-2 px-4 border-b border-gray-200">£{extra?.toFixed(2)}</td>
                                </tr> : null}
                                <tr >
                                    <td className="py-2 px-4 border-b border-gray-200"></td>
                                    <td className="py-2 px-4 border-b border-gray-200"></td>
                                    <td className="py-2 px-4 border-b border-gray-200"></td>
                                    <td className="py-2 px-2 border-b border-gray-200 w-32">                                </td>
                                    <td className="py-2 px-4 border-b border-gray-200"></td>
                                    <td className="py-2 px-4 border-b border-gray-200">Final Price</td>
                                    <td className="py-2 px-4 border-b border-gray-200">£{order?.discount>0 ? final.toFixed(2) : order?.totalPrice?.toFixed(2)}</td>
                                </tr>
                    </tbody>
                </table>
            </div>
            <div className="flex flex-center items-center justify-center w-full lg:w-1/2 mx-auto">
            <button
                            onClick={handleModifyPdf}
                             className="inline-flex w-auto justify-center items-center px-6 lg:px-12 rounded-full bg-blue-500 text-white font-bold h-[49px] lg:h-[65px] text-[15px] xl:text-[15px] mb-2"
                          >
                            Download and print label
                          </button>
            </div>
          
        </div>
      </Modal>
      )};

export default SuccessDetails;