import React from 'react';
import { TrashIcon} from '@heroicons/react/20/solid';

const ProductSummary = (props) => {
  const productDetails = props.productDetails;
    return (
      <div className='flex flex-col w-full'>
        <div className="font-bold text-xl md:text-base items-center ify-bejusttween pb-4 mb-3 border-b-2">
        <div className="flex mb-4 pb-4 border-b-2 ">
            <p>Basket</p>
            {/* <span className="text-gray-500 font-bold">{props.productDetails.qty} items</span> */}
          </div>
          <div className="flex justify-between mb-1 mx-2 ">
            <p className='w-1/2'>{productDetails?.name} {productDetails?.productId}</p>
            <div className='flex items-center w-1/2 justify-between mx-3'>
              <span className="text-black font-bold">{productDetails?.condition}</span>
              <span className="text-black font-bold">Offer price</span>
              <span className="text-black font-bold">£{productDetails?.price}</span>
            </div>
          </div>
          <div className="flex w-full items-center justify-between mx-1 px-4">
            <img src={productDetails?.img} alt={productDetails?.name} className="h-12 w-12" />
            <span className='text-sm text-black'>
              Qty
            </span> 
            <div className="flex items-center justify-between">
            <button type="button" id="decrement-button-2" data-input-counter-decrement="counter-input-2" className="inline-flex h-6 w-6 shrink-0 items-center justify-center rounded-md border border-gray-300 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700" onClick={props.decrement}>
              <svg className="h-2.5 w-2.5 text-black dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 2">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h16"></path>
              </svg>
            </button>
            <input type="text" id="counter-input-2" data-input-counter="" className="w-10 shrink-0 border-0 bg-transparent text-center text-sm font-medium text-black focus:outline-none focus:ring-0 dark:text-white" placeholder="" value={productDetails?.qty} required=""></input>
            <button type="button" id="increment-button-2" data-input-counter-increment="counter-input-2" className="inline-flex h-6 w-6 shrink-0 items-center justify-center rounded-md border border-gray-300 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700" onClick={props.increment}>
              <svg className="h-2.5 w-2.5 text-black dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
               <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 1v16M1 9h16"></path>
              </svg>
            </button>
          </div>
            <button type="button" className='focus:outline-none focus:ring-0 active:outline-none active:ring-0' onClick={props.removeProduct}>
              <TrashIcon className="h-6 w-6 text-red-700 mx-2 " />
            </button>
            <span className="text-black font-bold justify-end">£{productDetails?.price}</span>
          </div>
        </div>
        <div className="flex justify-between mx-4">
            <p >Total</p> 
            <span className="text-black text-xl font-bold">£{productDetails?.subTotal?.toFixed(2)}</span>
          </div>
      </div>
    );
};

export default ProductSummary;
